import React, { useEffect } from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";

const { width } = Glob.get("dimensions");

export default function OnespotCreateTypeGame({ navigation }) {
  useEffect(() => {
    Analytics.logEvent("view_onespotCreateTypeGame");
  }, []);

  return (
    <View style={styles.mainView}>
      <NavBar isOnespot navigation={navigation} text="Create a Game" />
      <ScrollView
        style={{ width, height: "100%" }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ marginHorizontal: 15, marginTop: 20, marginBottom: 50 }}>
          <Text
            style={[
              Style.get("headerText"),
              { color: Glob.get("primaryColor") }
            ]}
          >
            Onespot doesn't yet support making games.
          </Text>
          <Text style={Style.get("subheaderText")}>
            The Onespot app-building platform is designed specifically for
            businesses, organizations, communities, and other similar projects.
            {"\n\n"}To see what types of apps people create on Onespot, feel
            free to explore all published spots:
          </Text>
          <Button
            text="Explore & Join"
            onPress={() => navigation.push("onespotJoin")}
            color={Glob.get("primaryColor")}
          />
          <Text style={Style.get("subheaderText")}>
            {"\n"}Or, if you know you want to make a game, here are a few of our
            favorite game-making platforms that we recommend trying instead:
          </Text>
          <Text
            style={{
              color: Glob.get("primaryColor"),
              marginTop: 30,
              fontSize: 32
            }}
          >
            Easy-to-Use
          </Text>
          <Text style={Style.get("subheaderText")}>
            Great platforms for 2D mobile games
          </Text>
          <Button
            text="GameSalad"
            onPress={() => Util.openURL("https://gamesalad.com")}
            color={Glob.get("primaryColor")}
            icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
            outline
            options={{ alignLeft: true }}
            style={styles.button}
          />
          <Button
            text="GameMaker"
            onPress={() => Util.openURL("https://gamemaker.io")}
            color={Glob.get("primaryColor")}
            icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
            outline
            options={{ alignLeft: true }}
            style={styles.button}
          />
          <Button
            text="Stencyl"
            onPress={() => Util.openURL("https://www.stencyl.com")}
            color={Glob.get("primaryColor")}
            icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
            outline
            options={{ alignLeft: true }}
            style={styles.button}
          />
          <Text
            style={{
              color: Glob.get("primaryColor"),
              marginTop: 30,
              fontSize: 32
            }}
          >
            Advanced
          </Text>
          <Text style={Style.get("subheaderText")}>
            Best platforms for more advanced, 3D games
          </Text>
          <Button
            text="Unity"
            onPress={() =>
              Util.openURL(
                "https://prf.hn/click/camref:1100l3WUHA/destination:https%3A%2F%2Fstore.unity.com%2Fproducts%2Funity-plus"
              )
            }
            color={Glob.get("primaryColor")}
            icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
            outline
            options={{ alignLeft: true }}
            style={styles.button}
          />
          <Button
            text="Unreal Engine"
            onPress={() => Util.openURL("https://www.unrealengine.com")}
            color={Glob.get("primaryColor")}
            icon="d24166f4-d993-4d3a-8692-ae839b70b6af"
            outline
            options={{ alignLeft: true }}
            style={styles.button}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    height: "100%",
    backgroundColor: "white",
    alignItems: "center"
  },
  button: {
    width: "100%"
  }
});
