import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Rex from "src/globalState";
import Covid19Form from "src/screens/covid19form";
import Dynamic from "src/screens/dynamic";
import NavWebView from "src/components/navWebView";
import Events from "src/screens/events";
import ActivityFeed from "src/screens/activityFeed";
import ListWebNavs from "src/screens/listWebNavs";
import ListWebNavsSplit from "src/screens/listWebNavsSplit";
import Root from "src/screens/root";
import Form from "src/screens/form";

const PORTAL_KEY_TO_COMPONENT = {
  covid19form: <Covid19Form />
};

export default function MiniScreen({
  navigation,
  portalType,
  navName,
  contentTitle,
  contentURL,
  backgroundColor,
  dynamicContent,
  portalContent,
  globalConfigOverride
}) {
  if (portalType === "listWebNavs") {
    return (
      <ListWebNavs
        navigation={navigation}
        portalOverride={{ name: navName, title: contentTitle }}
      />
    );
  }
  if (portalType === "listWebNavsSplit") {
    return (
      <ListWebNavsSplit
        navigation={navigation}
        portalOverride={{ name: navName, title: contentTitle }}
      />
    );
  }
  if (["webNav", "webStatic", "pdfDocument"].includes(portalType)) {
    return (
      <NavWebView
        navigation={navigation}
        title={contentTitle}
        URL={contentURL}
        isFromMiniScreen
      />
    );
  }
  if (portalType === "dynamic") {
    return (
      <Dynamic
        navigation={navigation}
        portalOverride={{
          title: contentTitle,
          content: dynamicContent,
          backgroundColor,
          isFromMiniScreen: true
        }}
      />
    );
  }
  if (portalType === "events") {
    return <Events navName={navName} portalContentOverride={portalContent} />;
  }
  if (portalType === "activityFeed") {
    return (
      <ActivityFeed navName={navName} portalContentOverride={portalContent} />
    );
  }
  if (portalType === "form") {
    // TODO: Fix this
    // return <Form navName={navName} portalContentOverride={portalContent} />;
    return (
      <View style={styles.container}>
        <Text style={{ color: Rex.getConfig()?.colors?.text, fontSize: 32 }}>
          ✨ Custom Form ✨
        </Text>
        <Text style={styles.text}>This is a custom form we built for you.</Text>
      </View>
    );
  }
  if (portalType === "root") {
    return (
      <Root
        navigation={navigation}
        isFromMiniScreen
        globalConfigOverride={globalConfigOverride}
      />
    );
  }
  if (portalType === "native" || true) {
    if (Object.keys(PORTAL_KEY_TO_COMPONENT).includes(navName)) {
      return PORTAL_KEY_TO_COMPONENT[navName];
    }
    return (
      <View style={styles.container}>
        <Text style={{ color: Rex.getConfig()?.colors?.text, fontSize: 32 }}>
          ✨ Custom Screen ✨
        </Text>
        <Text style={styles.text}>{`This is a custom screen we built for you.

If you would like this content updated, we would be happy to help!

Please reach out to a member of the Onespot team for assistance.

If you're not sure who to contact, you can always reach us at team@seabirdapps.com.`}</Text>
      </View>
    );
  }
  // TODO: Enable other things (Dynamic Forms, etc) -- we currently never hit this code
  return <Text>No content found, sorry 😕</Text>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    backgroundColor: "white"
  },
  text: {
    fontSize: 20,
    marginTop: 20
  }
});
