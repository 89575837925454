import { TOUCH_ACTION_COMPUTE } from "./touchactionjs/touchaction-Consts";
import TapRecognizer from "./recognizers/tap";
import PanRecognizer from "./recognizers/pan";
import SwipeRecognizer from "./recognizers/swipe";
import PinchRecognizer from "./recognizers/pinch";
import RotateRecognizer from "./recognizers/rotate";
import PressRecognizer from "./recognizers/press";
import {DIRECTION_HORIZONTAL} from "./inputjs/input-consts";

export default {
	/**
	 * @private
	 * set if DOM events are being triggered.
	 * But this is slower and unused by simple implementations, so disabled by default.
	 * @type {Boolean}
	 * @default false
	 */
	domEvents: false,

	/**
	 * @private
	 * The value for the touchAction property/fallback.
	 * When set to `compute` it will magically set the correct value based on the added recognizers.
	 * @type {String}
	 * @default compute
	 */
	touchAction: TOUCH_ACTION_COMPUTE,

	/**
	 * @private
	 * @type {Boolean}
	 * @default true
	 */
	enable: true,

	/**
	 * @private
	 * EXPERIMENTAL FEATURE -- can be removed/changed
	 * Change the parent input target element.
	 * If Null, then it is being set the to main element.
	 * @type {Null|EventTarget}
	 * @default null
	 */
	inputTarget: null,

	/**
	 * @private
	 * force an input class
	 * @type {Null|Function}
	 * @default null
	 */
	inputClass: null,

	/**
	 * @private
	 * Some CSS properties can be used to improve the working of Hammer.
	 * Add them to this method and they will be set when creating a new Manager.
	 * @namespace
	 */
	cssProps: {
		/**
		 * @private
		 * Disables text selection to improve the dragging gesture. Mainly for desktop browsers.
		 * @type {String}
		 * @default 'none'
		 */
		userSelect: "none",

		/**
		 * @private
		 * Disable the Windows Phone grippers when pressing an element.
		 * @type {String}
		 * @default 'none'
		 */
		touchSelect: "none",

		/**
		 * @private
		 * Disables the default callout shown when you touch and hold a touch target.
		 * On iOS, when you touch and hold a touch target such as a link, Safari displays
		 * a callout containing information about the link. This property allows you to disable that callout.
		 * @type {String}
		 * @default 'none'
		 */
		touchCallout: "none",

		/**
		 * @private
		 * Specifies whether zooming is enabled. Used by IE10>
		 * @type {String}
		 * @default 'none'
		 */
		contentZooming: "none",

		/**
		 * @private
		 * Specifies that an entire element should be draggable instead of its contents. Mainly for desktop browsers.
		 * @type {String}
		 * @default 'none'
		 */
		userDrag: "none",

		/**
		 * @private
		 * Overrides the highlight color shown when the user taps a link or a JavaScript
		 * clickable element in iOS. This property obeys the alpha value, if specified.
		 * @type {String}
		 * @default 'rgba(0,0,0,0)'
		 */
		tapHighlightColor: "rgba(0,0,0,0)",
	},
};

/**
 * @private
 * Default recognizer setup when calling `Hammer()`
 * When creating a new Manager these will be skipped.
 * This is separated with other defaults because of tree-shaking.
 * @type {Array}
 */
export const preset = [
  [RotateRecognizer, { enable: false }],
  [PinchRecognizer, { enable: false }, ['rotate']],
  [SwipeRecognizer, { direction: DIRECTION_HORIZONTAL }],
  [PanRecognizer, { direction: DIRECTION_HORIZONTAL }, ['swipe']],
  [TapRecognizer],
  [TapRecognizer, { event: 'doubletap', taps: 2 }, ['tap']],
  [PressRecognizer]
];
