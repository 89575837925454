import React from 'react';
import reactCSS from 'reactcss';

export var PhotoshopPointerCircle = function PhotoshopPointerCircle() {
  var styles = reactCSS({
    'default': {
      triangle: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '4px 0 4px 6px',
        borderColor: 'transparent transparent transparent #fff',
        position: 'absolute',
        top: '1px',
        left: '1px'
      },
      triangleBorder: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '5px 0 5px 8px',
        borderColor: 'transparent transparent transparent #555'
      },

      left: {
        Extend: 'triangleBorder',
        transform: 'translate(-13px, -4px)'
      },
      leftInside: {
        Extend: 'triangle',
        transform: 'translate(-8px, -5px)'
      },

      right: {
        Extend: 'triangleBorder',
        transform: 'translate(20px, -14px) rotate(180deg)'
      },
      rightInside: {
        Extend: 'triangle',
        transform: 'translate(-8px, -5px)'
      }
    }
  });

  return React.createElement(
    'div',
    { style: styles.pointer },
    React.createElement(
      'div',
      { style: styles.left },
      React.createElement('div', { style: styles.leftInside })
    ),
    React.createElement(
      'div',
      { style: styles.right },
      React.createElement('div', { style: styles.rightInside })
    )
  );
};

export default PhotoshopPointerCircle;