import React from "react";
import ContentRenderer from "src/components/ContentRenderer";

export default function Dynamic({ route, navigation, portalOverride }) {
  return (
    <ContentRenderer
      route={route}
      navigation={navigation}
      portalOverride={portalOverride}
    />
  );
}
