/* A navigation bar containing two touchable buttons on either side, and text in the center. */

import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Platform,
  Animated,
  useColorScheme
} from "react-native";
import { useIsFocused } from "@react-navigation/native";
import LottieView from "src/components/Lottie";
import {
  Feather,
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome
} from "@expo/vector-icons";
import { StatusBar } from "expo-status-bar";
import * as Haptics from "expo-haptics";
import Firebase from "src/backend/firebase";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import BackButton from "src/components/backButton";
import { BlurView } from "expo-blur";
import Database from "src/backend/database";

const NOTIFICATION_PULSE_ANIMATION = require("resources/animations/notificationPulseRed.json");

const { width, height } = Dimensions.get("window");
const { width: widthWithMargins } = Glob.get("dimensions");

let NAVBAR_FONT_SIZE = 0.032 * height;
if (widthWithMargins < 340) NAVBAR_FONT_SIZE = 0.05 * widthWithMargins;

const ICON_SIZE = Math.min(0.07 * widthWithMargins, 42);
const NAVBAR_TOP_PADDING = 0.035 * height + 12;

export default function NavBar({
  navigation,
  screenType,
  checkRefresh,
  onUpdatePortal,
  text = "",
  webViewIsLoading,
  onShare,
  cannotNavigateBack,
  type,
  canGoBack,
  canGoForward,
  onPressGoBack,
  onPressGoForward,
  adminPrivileges,
  turnOffAdminPrivileges,
  unopenedNotification,
  unopenedNotificationInAnotherJoinedApp,
  unopenedTutorialTask,
  hideUnopenedTutorialTask = () => {},
  LeftButton = null,
  RightButton = null,
  LeftButtonSecondary = null,
  RightButtonSecondary = null,
  isOnespot = false,
  backgroundColor,
  buttonColor,
  onPressBackButton,
  userIsDemoAccount = false
}) {
  const [imageURL, setImageURL] = useState(null);
  const [notification, setNotification] = useState(null);
  const color = buttonColor || "white";
  const showDemoView = Platform.OS === "web" && userIsDemoAccount;

  const colorScheme = useColorScheme();
  const isDarkMode = colorScheme === "dark";
  const slideAnim = useRef(
    new Animated.Value(-1 * Glob.get("navBarHeight") - NAVBAR_TOP_PADDING)
  ).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const progressAnim = useRef(new Animated.Value(0)).current;

  const isFocused = useIsFocused();

  const slideDown = () => {
    Animated.parallel([
      Animated.spring(slideAnim, {
        toValue: 10,
        useNativeDriver: true,
        speed: 12,
        bounciness: 8
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true
      })
    ]).start();
  };

  const slideUp = () => {
    Animated.parallel([
      Animated.spring(slideAnim, {
        toValue: -1 * Glob.get("navBarHeight") - NAVBAR_TOP_PADDING,
        useNativeDriver: true,
        speed: 12,
        bounciness: 8
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true
      })
    ]).start();
  };

  const startProgress = () => {
    progressAnim.setValue(0);
    Animated.timing(progressAnim, {
      toValue: 1,
      duration: 4500, // 4.5 seconds
      useNativeDriver: true
    }).start();
  };

  useEffect(() => {
    if (notification) {
      slideDown();
      startProgress();
    } else {
      slideUp();
    }
  }, [notification]);

  useEffect(() => {
    // Only set up the interval if this screen is focused
    if (!isFocused) return;

    // Every 2 second, check if there is a new notification in session memory
    const interval = setInterval(() => {
      const newNotification = Rex.getSessionMemory("newNotification");
      if (newNotification) {
        if (Platform.OS !== "web")
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
        setNotification(newNotification);
        Rex.setSessionMemory("newNotification", null);
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      }
    }, 2000);

    // eslint-disable-next-line
    return () => clearInterval(interval);
  }, [isFocused]);

  useEffect(() => {
    Firebase.getMediaURLAsync("navbar.png")
      .then(setImageURL)
      .catch(() => {});
  }, []);

  const iPhoneXFontSizeMultiplier = Glob.deviceHasiPhoneXDimensions()
    ? 0.75
    : 1;
  const navBarColor = backgroundColor || Rex.getConfig()?.colors?.navbar;
  const fontSizeMultiplier = Math.min(
    Math.max(1 + (13 - text.length) * 0.03, 0.5) * iPhoneXFontSizeMultiplier,
    1
  );
  const fontSize = NAVBAR_FONT_SIZE * fontSizeMultiplier;
  const paddingTop = (NAVBAR_FONT_SIZE - fontSize) * 0.5;
  const title = text.truncate(36).toUpperCase();
  const showSideMenu = !Util.appIsStandalone();

  const navigateToPortal = (portal) => {
    if (portal) {
      if (portal.portalType === "native") {
        // e.g. "food"
        navigation.push(portal, {
          navName: portal.portalID,
          txtName: portal.txtName,
          icon: portal.icon,
          portalType: portal.portalType
        });
      } else {
        // e.g. "webNav"
        navigation.push(portal.portalType, {
          navName: portal.portalID,
          txtName: portal.txtName,
          icon: portal.icon,
          portalType: portal.portalType
        });
      }
    }
  };

  const renderTitle = () => {
    if (imageURL)
      return <Image source={{ uri: imageURL }} style={styles.titleImage} />;
    return (
      <View style={{ flex: 3, justifyContent: "center" }}>
        <Text
          style={[styles.rootTitle, { fontSize, paddingTop, color }]}
          adjustsFontSizeToFit
        >
          {title}
        </Text>
      </View>
    );
  };

  const isEmbedded = Platform.OS === "web" && Glob.get("urlParams")?.embedded;

  const renderNotificationBanner = () => (
    <Animated.View
      key={notification?.key || "notificationBanner"}
      style={{
        width: 0.8 * widthWithMargins,
        marginLeft: Platform.OS !== "web" ? 0.1 * widthWithMargins : 0,
        height: Glob.get("navBarHeight"),
        paddingTop: NAVBAR_TOP_PADDING + 20,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999999999,
        position: Platform.OS === "web" ? "fixed" : "absolute",
        opacity: fadeAnim,
        transform: [{ translateY: slideAnim }]
      }}
    >
      <BlurView
        intensity={Platform.OS === "android" ? 99 : 40}
        tint="systemThickMaterial"
        style={{
          width: "100%",
          height: Glob.get("navBarHeight"),
          overflow: "hidden",
          borderRadius: 20,
          shadowColor: "#000",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5
        }}
      >
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 1,
            width: 24,
            height: 24,
            justifyContent: "center",
            alignItems: "center"
          }}
          onPress={() => setNotification(null)}
        >
          <Ionicons
            name="close-circle"
            size={24}
            color={isDarkMode ? "white" : "black"}
            style={{ opacity: 0.6 }}
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            padding: 10,
            paddingRight: 40, // Prevents text from going under close button
            justifyContent: "center",
            alignItems: "flex-start"
          }}
          onPress={() => {
            if (notification) {
              const { portalID } = notification;
              if (portalID) {
                Database.getPortalMetadata(portalID).then((metadata) => {
                  if (metadata) {
                    navigateToPortal({ ...metadata, portalID });
                  }
                });
              } else {
                navigation.push("notificationDetails", {
                  notification
                });
              }
            }
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              color: isDarkMode ? "white" : "black"
            }}
          >
            {(notification?.title || "").truncate(80)}
          </Text>
          <Text style={{ color: isDarkMode ? "white" : "black" }}>
            {(notification?.body || "").truncate(80)}
          </Text>
        </TouchableOpacity>
        <Animated.View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 5,
            backgroundColor: navBarColor,
            transform: [
              {
                scaleX: progressAnim
              }
            ],
            transformOrigin: "left"
          }}
        />
      </BlurView>
    </Animated.View>
  );

  if (screenType === "root" || screenType === "rootCustomize") {
    return (
      <>
        <View style={[styles.pageHeader, { backgroundColor: navBarColor }]}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            {screenType !== "rootCustomize" && (
              <TouchableOpacity
                style={{ flex: 1, alignItems: "center" }}
                activeOpacity={0.6}
                onPress={
                  showSideMenu
                    ? onPressBackButton
                    : () =>
                        navigation.push("settings", {
                          type: "down",
                          checkRefresh
                        })
                }
              >
                <Ionicons
                  name={showSideMenu && !showDemoView ? "menu" : "person"}
                  size={ICON_SIZE}
                  color={color}
                />
                {!!unopenedNotificationInAnotherJoinedApp &&
                  showSideMenu &&
                  !showDemoView && (
                    <LottieView
                      style={styles.newNotificationPulse}
                      autoPlay
                      loop
                      source={NOTIFICATION_PULSE_ANIMATION}
                    />
                  )}
              </TouchableOpacity>
            )}
            {screenType !== "rootCustomize" && (
              <TouchableOpacity
                style={{ flex: 1, alignItems: "center" }}
                activeOpacity={0.6}
                onPress={() => {
                  navigation.push("notifications", {
                    type: "down",
                    adminPrivileges,
                    checkRefresh,
                    onUpdatePortal
                  });
                }}
              >
                <View style={{ width: "100%", alignItems: "center" }}>
                  <MaterialCommunityIcons
                    name="chat"
                    size={ICON_SIZE}
                    color={color}
                  />
                  {!!unopenedNotification && (
                    <LottieView
                      style={styles.newNotificationPulse}
                      autoPlay
                      loop
                      source={NOTIFICATION_PULSE_ANIMATION}
                    />
                  )}
                </View>
              </TouchableOpacity>
            )}
          </View>
          {renderTitle()}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            {adminPrivileges && screenType !== "rootCustomize" ? (
              <TouchableOpacity
                style={{ flex: 1, alignItems: "center" }}
                activeOpacity={0.6}
                onPress={() => {
                  hideUnopenedTutorialTask();
                  navigation.push("admin", {
                    type: "down",
                    checkRefresh,
                    onUpdatePortal,
                    adminPrivileges,
                    turnOffAdminPrivileges
                  });
                }}
              >
                <MaterialCommunityIcons
                  name="lightning-bolt"
                  size={ICON_SIZE}
                  color={color}
                />
                {!!unopenedTutorialTask && (
                  <LottieView
                    style={styles.newNotificationPulse}
                    autoPlay
                    loop
                    source={NOTIFICATION_PULSE_ANIMATION}
                  />
                )}
              </TouchableOpacity>
            ) : (
              <View style={{ flex: 1 }}></View>
            )}
            <View style={{ flex: 1 }}>{RightButton}</View>
          </View>
          {/* eslint-disable-next-line */}
        <StatusBar style="light" translucent />
        </View>
        {renderNotificationBanner()}
      </>
    );
  }

  if (screenType === "webNav") {
    return (
      <>
        <View style={[styles.pageHeader, { backgroundColor: navBarColor }]}>
          <View style={{ flex: 1 }}>
            <BackButton
              navigation={navigation}
              type="normal"
              onPress={onPressBackButton}
              color={color}
            />
          </View>

          <Text style={[styles.title, { fontSize, paddingTop, color }]}>
            {title}
          </Text>

          <View style={{ flex: 1, alignItems: "flex-end" }}>
            {webViewIsLoading ? (
              <View>
                {/* For some baffling reason, ActivityIndicator crashes everything on Android here */}
                {Platform.OS === "android" ? (
                  <FontAwesome
                    name="hourglass-3"
                    size={24}
                    color={color}
                    style={{ marginRight: 10 }}
                  />
                ) : (
                  <ActivityIndicator
                    size="small"
                    color={color}
                    style={{ marginRight: 10 }}
                  />
                )}
              </View>
            ) : (
              <TouchableOpacity activeOpacity={0.6} onPress={onShare}>
                <Feather
                  name={Platform.OS === "web" ? "external-link" : "share"}
                  size={ICON_SIZE}
                  color={color}
                  style={{ paddingHorizontal: 10 }}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>

        {(!!canGoBack || !!canGoForward) && (
          <View
            style={[
              styles.webNavButtonsContainer,
              { backgroundColor: navBarColor }
            ]}
          >
            <TouchableOpacity
              activeOpacity={0.6}
              disabled={!canGoBack}
              onPress={onPressGoBack}
            >
              <AntDesign
                name="arrowleft"
                size={24}
                color={color}
                style={[!canGoBack ? styles.invisible : {}]}
              />
            </TouchableOpacity>

            <TouchableOpacity
              activeOpacity={0.6}
              disabled={!canGoForward}
              onPress={onPressGoForward}
            >
              <AntDesign
                name="arrowright"
                size={24}
                color={color}
                style={[!canGoForward ? styles.invisible : {}]}
              />
            </TouchableOpacity>
          </View>
        )}
        {renderNotificationBanner()}
      </>
    );
  }

  if (screenType === "share") {
    return (
      <>
        <View style={styles.pageHeader} />
        <View
          style={[
            styles.pageHeader,
            {
              backgroundColor: navBarColor,
              position: Platform.OS === "web" ? "fixed" : "absolute"
            }
          ]}
        >
          <View style={{ flex: 1 }}>
            <BackButton
              navigation={navigation}
              type="normal"
              onPress={onPressBackButton}
              color={color}
            />
          </View>
          <Text style={[styles.title, { fontSize, paddingTop, color }]}>
            {title}
          </Text>
          <View style={{ flex: 1, alignItems: "flex-end" }}>
            <TouchableOpacity
              style={styles.shareIcon}
              activeOpacity={0.6}
              onPress={onShare}
            >
              <Feather
                name={Platform.OS === "web" ? "external-link" : "share"}
                size={ICON_SIZE}
                color={color}
                style={{ paddingHorizontal: 10 }}
              />
            </TouchableOpacity>
          </View>
        </View>
        {renderNotificationBanner()}
      </>
    );
  }

  let TitleComponent;
  if (isOnespot) {
    if (!Rex.getMetaApp()?.name) {
      TitleComponent = (
        <Image
          source={Glob.get("onespotLogoImage")}
          style={{
            height: "80%",
            flex: 1,
            resizeMode: "contain"
          }}
        />
      );
    } else {
      TitleComponent = (
        <Text style={[styles.title, { fontSize, paddingTop }]}>
          {Rex.getMetaApp().name}
        </Text>
      );
    }
  } else {
    TitleComponent = (
      <Text style={[styles.title, { fontSize, paddingTop, color }]}>
        {title}
      </Text>
    );
  }

  const navigationDepth = navigation?.getState()?.routes?.length;

  // If user is view as a first webpage while unauthenticated
  const showAsLoginButton =
    Glob.get("urlParams")?.screen &&
    Platform.OS === "web" &&
    Rex.getConfig()?.publicPagesEnabled &&
    !Rex.getLoginStatus() &&
    navigationDepth < 3;
  const showSecondaryButtons = !!LeftButtonSecondary || !!RightButtonSecondary;

  return (
    <>
      <View style={styles.pageHeader} />
      <View
        style={[
          styles.pageHeader,
          {
            backgroundColor: isOnespot ? Glob.get("primaryColor") : navBarColor,
            position: Platform.OS === "web" ? "fixed" : "absolute"
          }
        ]}
      >
        {LeftButton ? (
          <View style={{ flex: 1, justifyContent: "center" }}>
            {LeftButton}
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {!cannotNavigateBack && !(showAsLoginButton && isEmbedded) && (
              <BackButton
                navigation={navigation}
                type={type}
                onPress={onPressBackButton}
                showAsLoginButton={showAsLoginButton}
                color={color}
              />
            )}
          </View>
        )}
        {showSecondaryButtons && (
          <View style={{ flex: 1, justifyContent: "center" }}>
            {LeftButtonSecondary}
          </View>
        )}
        {TitleComponent}
        {showSecondaryButtons && (
          <View style={{ flex: 1, justifyContent: "center" }}>
            {RightButtonSecondary}
          </View>
        )}
        <View style={{ flex: 1, justifyContent: "center" }}>{RightButton}</View>
      </View>
      {renderNotificationBanner()}
    </>
  );
}

const styles = StyleSheet.create({
  pageHeader: {
    width: "100%",
    height: Glob.get("navBarHeight"),
    paddingTop: NAVBAR_TOP_PADDING,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 999999999
  },

  webNavButtonsContainer: {
    width,
    paddingVertical: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },

  /* Style for the title of the navigation bar on the root page (school name is the title) */
  rootTitle: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: Glob.deviceHasiPhoneXDimensions() ? 0.25 * NAVBAR_FONT_SIZE : 0
  },

  /* Style for the title of the navigation bar */
  title: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center"
  },

  /* Optional title image */
  titleImage: {
    height: 0.05 * height,
    width: "58%",
    marginHorizontal: 5,
    resizeMode: "contain"
  },

  shareIcon: {
    height: 0.05 * height,
    width: 0.05 * height,
    marginLeft: -0.04 * width
  },

  /* Style for an invisible icon on the right side of the header, for the purposes of proper spacing */
  invisible: {
    width: 0.16 * width,
    height: 0.05 * height,
    opacity: 0
  },

  newNotificationPulse: {
    width: 40,
    height: 40,
    position: "absolute",
    right: -15,
    top: -15
  }
});
