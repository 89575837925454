import React, { useState } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import Style from "src/globalStyles";
import Icon from "src/components/Icon";
import Dropdown from "src/components/Dropdown";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";

const ListItem = ({ text, inputName, onRemove, onChangeInput }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#f7f7f7",
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 20,
        marginBottom: 8,
        marginTop: 5
      }}
    >
      <View style={{ flex: 8, justifyContent: "center", marginRight: 10 }}>
        <Text style={{ fontSize: 14 }}>{text}</Text>
      </View>
      {!!inputName && (
        <View style={{ flex: 5 }}>
          <InputBox
            flexibleWidth
            placeholder={inputName}
            style={{ marginTop: 0 }}
            keyboardType={inputName === "Quantity" ? "number-pad" : "default"}
            onChangeText={onChangeInput}
          />
        </View>
      )}
      <View style={{ flex: 1 }} />
      <TouchableOpacity
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "flex-end"
        }}
        onPress={onRemove}
      >
        <Icon set="MaterialIcons" name="close" color="gray" size={24} />
      </TouchableOpacity>
    </View>
  );
};

export default function ListBuilderInput({
  header,
  showAsOptional,
  listItems = [],
  listItemInputName,
  dropdownOptions = [],
  onUpdateListItems,
  placeholder = "Search"
}) {
  const [isAdding, setIsAdding] = useState(false);

  const filteredDropdownOptions = dropdownOptions.filter(
    (option) =>
      !listItems || !listItems.some((item) => item.text === option.text)
  );

  return (
    <View style={{ marginTop: 20 }}>
      <Text style={Style.get("headerText")}>{header}</Text>
      {!!listItems &&
        listItems.map((item) => (
          <ListItem
            text={item.text}
            inputName={listItemInputName}
            onRemove={() => {
              onUpdateListItems(listItems.filter((i) => i.text !== item.text));
            }}
            onChangeInput={(inputValue) => {
              onUpdateListItems(
                listItems.map((i) =>
                  i.text === item.text ? { ...i, inputValue } : i
                )
              );
            }}
          />
        ))}
      {isAdding ? (
        <Dropdown
          showAsOptional={showAsOptional}
          startOpen
          enableAutocomplete
          items={filteredDropdownOptions}
          onSelect={(newItem) => {
            onUpdateListItems([...listItems, { text: newItem }]);
            setIsAdding(false);
          }}
          onCollapse={() => {
            setIsAdding(false);
          }}
          placeholder={placeholder}
        />
      ) : (
        <Button
          text="Add"
          onPress={() => setIsAdding(true)}
          small
          flat
          outline
          align="left"
          icon="plus"
          color="#4f4f4f"
        />
      )}
    </View>
  );
}
