export function hasCustomScheme(): boolean {
  return true;
}
export function collectManifestSchemes(): string[] {
  return [];
}
export function hasConstantsManifest(): boolean {
  return false;
}
export function resolveScheme(): string {
  return 'https';
}
