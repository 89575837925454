import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as FileSaver from "file-saver";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Glob from "src/globalConstants";
import moment from "moment";

const { width } = Glob.get("dimensions");
const ICON_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

export default function ExportUsersButton({
  title = "All Members",
  users = []
}) {
  const [allAccountTypes, setAllAccountTypes] = useState({});
  const [customInputFieldsArray, setCustomInputFieldsArray] = useState([]);

  useEffect(() => {
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) setAllAccountTypes(details);
    });
    Database.fetchUserAccountFields().then(({ fieldsArray }) => {
      setCustomInputFieldsArray(fieldsArray || []);
    });
  }, []);

  const exportUsersAsCSV = () => {
    Analytics.logEvent("touch_manageUsers_exportAsCSV");

    let columns = [
      // "User ID",
      "Name",
      "Email",
      "Account Type",
      "Status",
      "Date Invited",
      "Date Joined"
    ];
    if (customInputFieldsArray.length > 0) {
      columns = [
        ...columns,
        ...customInputFieldsArray.map((field) => field.title)
      ];
    }
    // remove all commas from columns
    columns = columns.map((item) => `${item || ""}`.replace(/,/g, ""));
    columns = `${columns.join(",")}\n`;
    // format users as array for csv export
    const usersFormattedForCSV = users.map((user) => {
      let row = [
        // user.uid || "",
        user.firstName || user.lastName
          ? `${user.firstName || ""} ${user.lastName || ""}`
          : "",
        user.email || "",
        user.type && allAccountTypes[user.type]?.title
          ? allAccountTypes[user.type].title
          : "",
        user.isInvitation ? "Invited" : "Joined",
        user.invitedAtTimestamp
          ? moment(user.invitedAtTimestamp).format("MM/DD/YYYY h:mm A")
          : "",
        user.createdAtTimestamp
          ? moment(user.createdAtTimestamp).format("MM/DD/YYYY h:mm A")
          : ""
      ];
      if (customInputFieldsArray.length > 0) {
        row = [
          ...row,
          ...customInputFieldsArray.map((field) => {
            const { key } = field;
            return user[key] || "";
          })
        ];
      }
      // remove all commas from row
      row = row.map((item) => `${item || ""}`.replace(/,/g, ""));
      return `${row.join(",")}\n`;
    });

    const csvArray = [columns, ...usersFormattedForCSV];
    const blob = new Blob(csvArray, {
      type: "text/csv;charset=utf-8;"
    });
    FileSaver.saveAs(blob, `${title}.csv`);
  };

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight: 10
      }}
      activeOpacity={0.6}
      onPress={exportUsersAsCSV}
    >
      <MaterialCommunityIcons
        name="file-download-outline"
        size={ICON_SIZE}
        color="white"
      />
    </TouchableOpacity>
  );
}
