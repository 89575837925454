import React from "react";
import {
  View,
  TouchableHighlight,
  TextInput,
  Dimensions,
  ActivityIndicator
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";

const { width } = Dimensions.get("window");

export default function SearchBar({
  placeholder,
  onChangeText,
  isSearching,
  customWidth
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
        width: customWidth || 0.83 * width,
        maxWidth: 400, // handle tablet
        height: 40,
        marginTop: 12,
        alignSelf: "center",
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: 7,
        zIndex: -1 // this is to make sure the search bar is behind the notification banner in NavBar
      }}
    >
      <TextInput
        style={{
          height: "100%",
          flex: 5,
          color: "white",
          paddingLeft: 20,
          paddingRight: 10,
          fontSize: 20
        }}
        placeholder={placeholder}
        placeholderTextColor="rgba(255, 255, 255, 0.7)" // translucent white
        selectionColor="white"
        returnKeyType="done"
        onChangeText={(text) => {
          if (onChangeText) onChangeText(text);
        }}
      />
      <TouchableHighlight
        underlayColor="transparent"
        style={{ flex: 1, alignItems: "flex-end", marginRight: 10 }}
      >
        {isSearching ? (
          <ActivityIndicator color="white" />
        ) : (
          <FontAwesome5
            name="search"
            size={20}
            color="rgba(255, 255, 255, 0.7)"
          />
        )}
      </TouchableHighlight>
    </View>
  );
}
