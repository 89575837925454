// This file is autogenerated by tools/process-named-references.ts
/* eslint-disable */

export type NamedReferences = {
    [K in 'xml' | 'html4' | 'html5']: {
        entities: Record<string, string>;
        characters: Record<string, string>;
    }
};
export const bodyRegExps = {
    xml: /&(?:#\d+|#[xX][\da-fA-F]+|[0-9a-zA-Z]+);?/g,
    html4: /&notin;|&(?:nbsp|iexcl|cent|pound|curren|yen|brvbar|sect|uml|copy|ordf|laquo|not|shy|reg|macr|deg|plusmn|sup2|sup3|acute|micro|para|middot|cedil|sup1|ordm|raquo|frac14|frac12|frac34|iquest|Agrave|Aacute|Acirc|Atilde|Auml|Aring|AElig|Ccedil|Egrave|Eacute|Ecirc|Euml|Igrave|Iacute|Icirc|Iuml|ETH|Ntilde|Ograve|Oacute|Ocirc|Otilde|Ouml|times|Oslash|Ugrave|Uacute|Ucirc|Uuml|Yacute|THORN|szlig|agrave|aacute|acirc|atilde|auml|aring|aelig|ccedil|egrave|eacute|ecirc|euml|igrave|iacute|icirc|iuml|eth|ntilde|ograve|oacute|ocirc|otilde|ouml|divide|oslash|ugrave|uacute|ucirc|uuml|yacute|thorn|yuml|quot|amp|lt|gt|#\d+|#[xX][\da-fA-F]+|[0-9a-zA-Z]+);?/g,
    html5: /&centerdot;|&copysr;|&divideontimes;|&gtcc;|&gtcir;|&gtdot;|&gtlPar;|&gtquest;|&gtrapprox;|&gtrarr;|&gtrdot;|&gtreqless;|&gtreqqless;|&gtrless;|&gtrsim;|&ltcc;|&ltcir;|&ltdot;|&lthree;|&ltimes;|&ltlarr;|&ltquest;|&ltrPar;|&ltri;|&ltrie;|&ltrif;|&notin;|&notinE;|&notindot;|&notinva;|&notinvb;|&notinvc;|&notni;|&notniva;|&notnivb;|&notnivc;|&parallel;|&timesb;|&timesbar;|&timesd;|&(?:AElig|AMP|Aacute|Acirc|Agrave|Aring|Atilde|Auml|COPY|Ccedil|ETH|Eacute|Ecirc|Egrave|Euml|GT|Iacute|Icirc|Igrave|Iuml|LT|Ntilde|Oacute|Ocirc|Ograve|Oslash|Otilde|Ouml|QUOT|REG|THORN|Uacute|Ucirc|Ugrave|Uuml|Yacute|aacute|acirc|acute|aelig|agrave|amp|aring|atilde|auml|brvbar|ccedil|cedil|cent|copy|curren|deg|divide|eacute|ecirc|egrave|eth|euml|frac12|frac14|frac34|gt|iacute|icirc|iexcl|igrave|iquest|iuml|laquo|lt|macr|micro|middot|nbsp|not|ntilde|oacute|ocirc|ograve|ordf|ordm|oslash|otilde|ouml|para|plusmn|pound|quot|raquo|reg|sect|shy|sup1|sup2|sup3|szlig|thorn|times|uacute|ucirc|ugrave|uml|uuml|yacute|yen|yuml|#\d+|#[xX][\da-fA-F]+|[0-9a-zA-Z]+);?/g
};
export const namedReferences: NamedReferences = {
    "xml": {
        "entities": {
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": "\"",
            "&apos;": "'",
            "&amp;": "&"
        },
        "characters": {
            "<": "&lt;",
            ">": "&gt;",
            "\"": "&quot;",
            "'": "&apos;",
            "&": "&amp;"
        }
    },
    "html4": {
        "entities": {
            "&apos;": "'",
            "&nbsp": " ",
            "&nbsp;": " ",
            "&iexcl": "¡",
            "&iexcl;": "¡",
            "&cent": "¢",
            "&cent;": "¢",
            "&pound": "£",
            "&pound;": "£",
            "&curren": "¤",
            "&curren;": "¤",
            "&yen": "¥",
            "&yen;": "¥",
            "&brvbar": "¦",
            "&brvbar;": "¦",
            "&sect": "§",
            "&sect;": "§",
            "&uml": "¨",
            "&uml;": "¨",
            "&copy": "©",
            "&copy;": "©",
            "&ordf": "ª",
            "&ordf;": "ª",
            "&laquo": "«",
            "&laquo;": "«",
            "&not": "¬",
            "&not;": "¬",
            "&shy": "­",
            "&shy;": "­",
            "&reg": "®",
            "&reg;": "®",
            "&macr": "¯",
            "&macr;": "¯",
            "&deg": "°",
            "&deg;": "°",
            "&plusmn": "±",
            "&plusmn;": "±",
            "&sup2": "²",
            "&sup2;": "²",
            "&sup3": "³",
            "&sup3;": "³",
            "&acute": "´",
            "&acute;": "´",
            "&micro": "µ",
            "&micro;": "µ",
            "&para": "¶",
            "&para;": "¶",
            "&middot": "·",
            "&middot;": "·",
            "&cedil": "¸",
            "&cedil;": "¸",
            "&sup1": "¹",
            "&sup1;": "¹",
            "&ordm": "º",
            "&ordm;": "º",
            "&raquo": "»",
            "&raquo;": "»",
            "&frac14": "¼",
            "&frac14;": "¼",
            "&frac12": "½",
            "&frac12;": "½",
            "&frac34": "¾",
            "&frac34;": "¾",
            "&iquest": "¿",
            "&iquest;": "¿",
            "&Agrave": "À",
            "&Agrave;": "À",
            "&Aacute": "Á",
            "&Aacute;": "Á",
            "&Acirc": "Â",
            "&Acirc;": "Â",
            "&Atilde": "Ã",
            "&Atilde;": "Ã",
            "&Auml": "Ä",
            "&Auml;": "Ä",
            "&Aring": "Å",
            "&Aring;": "Å",
            "&AElig": "Æ",
            "&AElig;": "Æ",
            "&Ccedil": "Ç",
            "&Ccedil;": "Ç",
            "&Egrave": "È",
            "&Egrave;": "È",
            "&Eacute": "É",
            "&Eacute;": "É",
            "&Ecirc": "Ê",
            "&Ecirc;": "Ê",
            "&Euml": "Ë",
            "&Euml;": "Ë",
            "&Igrave": "Ì",
            "&Igrave;": "Ì",
            "&Iacute": "Í",
            "&Iacute;": "Í",
            "&Icirc": "Î",
            "&Icirc;": "Î",
            "&Iuml": "Ï",
            "&Iuml;": "Ï",
            "&ETH": "Ð",
            "&ETH;": "Ð",
            "&Ntilde": "Ñ",
            "&Ntilde;": "Ñ",
            "&Ograve": "Ò",
            "&Ograve;": "Ò",
            "&Oacute": "Ó",
            "&Oacute;": "Ó",
            "&Ocirc": "Ô",
            "&Ocirc;": "Ô",
            "&Otilde": "Õ",
            "&Otilde;": "Õ",
            "&Ouml": "Ö",
            "&Ouml;": "Ö",
            "&times": "×",
            "&times;": "×",
            "&Oslash": "Ø",
            "&Oslash;": "Ø",
            "&Ugrave": "Ù",
            "&Ugrave;": "Ù",
            "&Uacute": "Ú",
            "&Uacute;": "Ú",
            "&Ucirc": "Û",
            "&Ucirc;": "Û",
            "&Uuml": "Ü",
            "&Uuml;": "Ü",
            "&Yacute": "Ý",
            "&Yacute;": "Ý",
            "&THORN": "Þ",
            "&THORN;": "Þ",
            "&szlig": "ß",
            "&szlig;": "ß",
            "&agrave": "à",
            "&agrave;": "à",
            "&aacute": "á",
            "&aacute;": "á",
            "&acirc": "â",
            "&acirc;": "â",
            "&atilde": "ã",
            "&atilde;": "ã",
            "&auml": "ä",
            "&auml;": "ä",
            "&aring": "å",
            "&aring;": "å",
            "&aelig": "æ",
            "&aelig;": "æ",
            "&ccedil": "ç",
            "&ccedil;": "ç",
            "&egrave": "è",
            "&egrave;": "è",
            "&eacute": "é",
            "&eacute;": "é",
            "&ecirc": "ê",
            "&ecirc;": "ê",
            "&euml": "ë",
            "&euml;": "ë",
            "&igrave": "ì",
            "&igrave;": "ì",
            "&iacute": "í",
            "&iacute;": "í",
            "&icirc": "î",
            "&icirc;": "î",
            "&iuml": "ï",
            "&iuml;": "ï",
            "&eth": "ð",
            "&eth;": "ð",
            "&ntilde": "ñ",
            "&ntilde;": "ñ",
            "&ograve": "ò",
            "&ograve;": "ò",
            "&oacute": "ó",
            "&oacute;": "ó",
            "&ocirc": "ô",
            "&ocirc;": "ô",
            "&otilde": "õ",
            "&otilde;": "õ",
            "&ouml": "ö",
            "&ouml;": "ö",
            "&divide": "÷",
            "&divide;": "÷",
            "&oslash": "ø",
            "&oslash;": "ø",
            "&ugrave": "ù",
            "&ugrave;": "ù",
            "&uacute": "ú",
            "&uacute;": "ú",
            "&ucirc": "û",
            "&ucirc;": "û",
            "&uuml": "ü",
            "&uuml;": "ü",
            "&yacute": "ý",
            "&yacute;": "ý",
            "&thorn": "þ",
            "&thorn;": "þ",
            "&yuml": "ÿ",
            "&yuml;": "ÿ",
            "&quot": "\"",
            "&quot;": "\"",
            "&amp": "&",
            "&amp;": "&",
            "&lt": "<",
            "&lt;": "<",
            "&gt": ">",
            "&gt;": ">",
            "&OElig;": "Œ",
            "&oelig;": "œ",
            "&Scaron;": "Š",
            "&scaron;": "š",
            "&Yuml;": "Ÿ",
            "&circ;": "ˆ",
            "&tilde;": "˜",
            "&ensp;": " ",
            "&emsp;": " ",
            "&thinsp;": " ",
            "&zwnj;": "‌",
            "&zwj;": "‍",
            "&lrm;": "‎",
            "&rlm;": "‏",
            "&ndash;": "–",
            "&mdash;": "—",
            "&lsquo;": "‘",
            "&rsquo;": "’",
            "&sbquo;": "‚",
            "&ldquo;": "“",
            "&rdquo;": "”",
            "&bdquo;": "„",
            "&dagger;": "†",
            "&Dagger;": "‡",
            "&permil;": "‰",
            "&lsaquo;": "‹",
            "&rsaquo;": "›",
            "&euro;": "€",
            "&fnof;": "ƒ",
            "&Alpha;": "Α",
            "&Beta;": "Β",
            "&Gamma;": "Γ",
            "&Delta;": "Δ",
            "&Epsilon;": "Ε",
            "&Zeta;": "Ζ",
            "&Eta;": "Η",
            "&Theta;": "Θ",
            "&Iota;": "Ι",
            "&Kappa;": "Κ",
            "&Lambda;": "Λ",
            "&Mu;": "Μ",
            "&Nu;": "Ν",
            "&Xi;": "Ξ",
            "&Omicron;": "Ο",
            "&Pi;": "Π",
            "&Rho;": "Ρ",
            "&Sigma;": "Σ",
            "&Tau;": "Τ",
            "&Upsilon;": "Υ",
            "&Phi;": "Φ",
            "&Chi;": "Χ",
            "&Psi;": "Ψ",
            "&Omega;": "Ω",
            "&alpha;": "α",
            "&beta;": "β",
            "&gamma;": "γ",
            "&delta;": "δ",
            "&epsilon;": "ε",
            "&zeta;": "ζ",
            "&eta;": "η",
            "&theta;": "θ",
            "&iota;": "ι",
            "&kappa;": "κ",
            "&lambda;": "λ",
            "&mu;": "μ",
            "&nu;": "ν",
            "&xi;": "ξ",
            "&omicron;": "ο",
            "&pi;": "π",
            "&rho;": "ρ",
            "&sigmaf;": "ς",
            "&sigma;": "σ",
            "&tau;": "τ",
            "&upsilon;": "υ",
            "&phi;": "φ",
            "&chi;": "χ",
            "&psi;": "ψ",
            "&omega;": "ω",
            "&thetasym;": "ϑ",
            "&upsih;": "ϒ",
            "&piv;": "ϖ",
            "&bull;": "•",
            "&hellip;": "…",
            "&prime;": "′",
            "&Prime;": "″",
            "&oline;": "‾",
            "&frasl;": "⁄",
            "&weierp;": "℘",
            "&image;": "ℑ",
            "&real;": "ℜ",
            "&trade;": "™",
            "&alefsym;": "ℵ",
            "&larr;": "←",
            "&uarr;": "↑",
            "&rarr;": "→",
            "&darr;": "↓",
            "&harr;": "↔",
            "&crarr;": "↵",
            "&lArr;": "⇐",
            "&uArr;": "⇑",
            "&rArr;": "⇒",
            "&dArr;": "⇓",
            "&hArr;": "⇔",
            "&forall;": "∀",
            "&part;": "∂",
            "&exist;": "∃",
            "&empty;": "∅",
            "&nabla;": "∇",
            "&isin;": "∈",
            "&notin;": "∉",
            "&ni;": "∋",
            "&prod;": "∏",
            "&sum;": "∑",
            "&minus;": "−",
            "&lowast;": "∗",
            "&radic;": "√",
            "&prop;": "∝",
            "&infin;": "∞",
            "&ang;": "∠",
            "&and;": "∧",
            "&or;": "∨",
            "&cap;": "∩",
            "&cup;": "∪",
            "&int;": "∫",
            "&there4;": "∴",
            "&sim;": "∼",
            "&cong;": "≅",
            "&asymp;": "≈",
            "&ne;": "≠",
            "&equiv;": "≡",
            "&le;": "≤",
            "&ge;": "≥",
            "&sub;": "⊂",
            "&sup;": "⊃",
            "&nsub;": "⊄",
            "&sube;": "⊆",
            "&supe;": "⊇",
            "&oplus;": "⊕",
            "&otimes;": "⊗",
            "&perp;": "⊥",
            "&sdot;": "⋅",
            "&lceil;": "⌈",
            "&rceil;": "⌉",
            "&lfloor;": "⌊",
            "&rfloor;": "⌋",
            "&lang;": "〈",
            "&rang;": "〉",
            "&loz;": "◊",
            "&spades;": "♠",
            "&clubs;": "♣",
            "&hearts;": "♥",
            "&diams;": "♦"
        },
        "characters": {
            "'": "&apos;",
            " ": "&nbsp;",
            "¡": "&iexcl;",
            "¢": "&cent;",
            "£": "&pound;",
            "¤": "&curren;",
            "¥": "&yen;",
            "¦": "&brvbar;",
            "§": "&sect;",
            "¨": "&uml;",
            "©": "&copy;",
            "ª": "&ordf;",
            "«": "&laquo;",
            "¬": "&not;",
            "­": "&shy;",
            "®": "&reg;",
            "¯": "&macr;",
            "°": "&deg;",
            "±": "&plusmn;",
            "²": "&sup2;",
            "³": "&sup3;",
            "´": "&acute;",
            "µ": "&micro;",
            "¶": "&para;",
            "·": "&middot;",
            "¸": "&cedil;",
            "¹": "&sup1;",
            "º": "&ordm;",
            "»": "&raquo;",
            "¼": "&frac14;",
            "½": "&frac12;",
            "¾": "&frac34;",
            "¿": "&iquest;",
            "À": "&Agrave;",
            "Á": "&Aacute;",
            "Â": "&Acirc;",
            "Ã": "&Atilde;",
            "Ä": "&Auml;",
            "Å": "&Aring;",
            "Æ": "&AElig;",
            "Ç": "&Ccedil;",
            "È": "&Egrave;",
            "É": "&Eacute;",
            "Ê": "&Ecirc;",
            "Ë": "&Euml;",
            "Ì": "&Igrave;",
            "Í": "&Iacute;",
            "Î": "&Icirc;",
            "Ï": "&Iuml;",
            "Ð": "&ETH;",
            "Ñ": "&Ntilde;",
            "Ò": "&Ograve;",
            "Ó": "&Oacute;",
            "Ô": "&Ocirc;",
            "Õ": "&Otilde;",
            "Ö": "&Ouml;",
            "×": "&times;",
            "Ø": "&Oslash;",
            "Ù": "&Ugrave;",
            "Ú": "&Uacute;",
            "Û": "&Ucirc;",
            "Ü": "&Uuml;",
            "Ý": "&Yacute;",
            "Þ": "&THORN;",
            "ß": "&szlig;",
            "à": "&agrave;",
            "á": "&aacute;",
            "â": "&acirc;",
            "ã": "&atilde;",
            "ä": "&auml;",
            "å": "&aring;",
            "æ": "&aelig;",
            "ç": "&ccedil;",
            "è": "&egrave;",
            "é": "&eacute;",
            "ê": "&ecirc;",
            "ë": "&euml;",
            "ì": "&igrave;",
            "í": "&iacute;",
            "î": "&icirc;",
            "ï": "&iuml;",
            "ð": "&eth;",
            "ñ": "&ntilde;",
            "ò": "&ograve;",
            "ó": "&oacute;",
            "ô": "&ocirc;",
            "õ": "&otilde;",
            "ö": "&ouml;",
            "÷": "&divide;",
            "ø": "&oslash;",
            "ù": "&ugrave;",
            "ú": "&uacute;",
            "û": "&ucirc;",
            "ü": "&uuml;",
            "ý": "&yacute;",
            "þ": "&thorn;",
            "ÿ": "&yuml;",
            "\"": "&quot;",
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            "Œ": "&OElig;",
            "œ": "&oelig;",
            "Š": "&Scaron;",
            "š": "&scaron;",
            "Ÿ": "&Yuml;",
            "ˆ": "&circ;",
            "˜": "&tilde;",
            " ": "&ensp;",
            " ": "&emsp;",
            " ": "&thinsp;",
            "‌": "&zwnj;",
            "‍": "&zwj;",
            "‎": "&lrm;",
            "‏": "&rlm;",
            "–": "&ndash;",
            "—": "&mdash;",
            "‘": "&lsquo;",
            "’": "&rsquo;",
            "‚": "&sbquo;",
            "“": "&ldquo;",
            "”": "&rdquo;",
            "„": "&bdquo;",
            "†": "&dagger;",
            "‡": "&Dagger;",
            "‰": "&permil;",
            "‹": "&lsaquo;",
            "›": "&rsaquo;",
            "€": "&euro;",
            "ƒ": "&fnof;",
            "Α": "&Alpha;",
            "Β": "&Beta;",
            "Γ": "&Gamma;",
            "Δ": "&Delta;",
            "Ε": "&Epsilon;",
            "Ζ": "&Zeta;",
            "Η": "&Eta;",
            "Θ": "&Theta;",
            "Ι": "&Iota;",
            "Κ": "&Kappa;",
            "Λ": "&Lambda;",
            "Μ": "&Mu;",
            "Ν": "&Nu;",
            "Ξ": "&Xi;",
            "Ο": "&Omicron;",
            "Π": "&Pi;",
            "Ρ": "&Rho;",
            "Σ": "&Sigma;",
            "Τ": "&Tau;",
            "Υ": "&Upsilon;",
            "Φ": "&Phi;",
            "Χ": "&Chi;",
            "Ψ": "&Psi;",
            "Ω": "&Omega;",
            "α": "&alpha;",
            "β": "&beta;",
            "γ": "&gamma;",
            "δ": "&delta;",
            "ε": "&epsilon;",
            "ζ": "&zeta;",
            "η": "&eta;",
            "θ": "&theta;",
            "ι": "&iota;",
            "κ": "&kappa;",
            "λ": "&lambda;",
            "μ": "&mu;",
            "ν": "&nu;",
            "ξ": "&xi;",
            "ο": "&omicron;",
            "π": "&pi;",
            "ρ": "&rho;",
            "ς": "&sigmaf;",
            "σ": "&sigma;",
            "τ": "&tau;",
            "υ": "&upsilon;",
            "φ": "&phi;",
            "χ": "&chi;",
            "ψ": "&psi;",
            "ω": "&omega;",
            "ϑ": "&thetasym;",
            "ϒ": "&upsih;",
            "ϖ": "&piv;",
            "•": "&bull;",
            "…": "&hellip;",
            "′": "&prime;",
            "″": "&Prime;",
            "‾": "&oline;",
            "⁄": "&frasl;",
            "℘": "&weierp;",
            "ℑ": "&image;",
            "ℜ": "&real;",
            "™": "&trade;",
            "ℵ": "&alefsym;",
            "←": "&larr;",
            "↑": "&uarr;",
            "→": "&rarr;",
            "↓": "&darr;",
            "↔": "&harr;",
            "↵": "&crarr;",
            "⇐": "&lArr;",
            "⇑": "&uArr;",
            "⇒": "&rArr;",
            "⇓": "&dArr;",
            "⇔": "&hArr;",
            "∀": "&forall;",
            "∂": "&part;",
            "∃": "&exist;",
            "∅": "&empty;",
            "∇": "&nabla;",
            "∈": "&isin;",
            "∉": "&notin;",
            "∋": "&ni;",
            "∏": "&prod;",
            "∑": "&sum;",
            "−": "&minus;",
            "∗": "&lowast;",
            "√": "&radic;",
            "∝": "&prop;",
            "∞": "&infin;",
            "∠": "&ang;",
            "∧": "&and;",
            "∨": "&or;",
            "∩": "&cap;",
            "∪": "&cup;",
            "∫": "&int;",
            "∴": "&there4;",
            "∼": "&sim;",
            "≅": "&cong;",
            "≈": "&asymp;",
            "≠": "&ne;",
            "≡": "&equiv;",
            "≤": "&le;",
            "≥": "&ge;",
            "⊂": "&sub;",
            "⊃": "&sup;",
            "⊄": "&nsub;",
            "⊆": "&sube;",
            "⊇": "&supe;",
            "⊕": "&oplus;",
            "⊗": "&otimes;",
            "⊥": "&perp;",
            "⋅": "&sdot;",
            "⌈": "&lceil;",
            "⌉": "&rceil;",
            "⌊": "&lfloor;",
            "⌋": "&rfloor;",
            "〈": "&lang;",
            "〉": "&rang;",
            "◊": "&loz;",
            "♠": "&spades;",
            "♣": "&clubs;",
            "♥": "&hearts;",
            "♦": "&diams;"
        }
    },
    "html5": {
        "entities": {
            "&AElig": "Æ",
            "&AElig;": "Æ",
            "&AMP": "&",
            "&AMP;": "&",
            "&Aacute": "Á",
            "&Aacute;": "Á",
            "&Abreve;": "Ă",
            "&Acirc": "Â",
            "&Acirc;": "Â",
            "&Acy;": "А",
            "&Afr;": "𝔄",
            "&Agrave": "À",
            "&Agrave;": "À",
            "&Alpha;": "Α",
            "&Amacr;": "Ā",
            "&And;": "⩓",
            "&Aogon;": "Ą",
            "&Aopf;": "𝔸",
            "&ApplyFunction;": "⁡",
            "&Aring": "Å",
            "&Aring;": "Å",
            "&Ascr;": "𝒜",
            "&Assign;": "≔",
            "&Atilde": "Ã",
            "&Atilde;": "Ã",
            "&Auml": "Ä",
            "&Auml;": "Ä",
            "&Backslash;": "∖",
            "&Barv;": "⫧",
            "&Barwed;": "⌆",
            "&Bcy;": "Б",
            "&Because;": "∵",
            "&Bernoullis;": "ℬ",
            "&Beta;": "Β",
            "&Bfr;": "𝔅",
            "&Bopf;": "𝔹",
            "&Breve;": "˘",
            "&Bscr;": "ℬ",
            "&Bumpeq;": "≎",
            "&CHcy;": "Ч",
            "&COPY": "©",
            "&COPY;": "©",
            "&Cacute;": "Ć",
            "&Cap;": "⋒",
            "&CapitalDifferentialD;": "ⅅ",
            "&Cayleys;": "ℭ",
            "&Ccaron;": "Č",
            "&Ccedil": "Ç",
            "&Ccedil;": "Ç",
            "&Ccirc;": "Ĉ",
            "&Cconint;": "∰",
            "&Cdot;": "Ċ",
            "&Cedilla;": "¸",
            "&CenterDot;": "·",
            "&Cfr;": "ℭ",
            "&Chi;": "Χ",
            "&CircleDot;": "⊙",
            "&CircleMinus;": "⊖",
            "&CirclePlus;": "⊕",
            "&CircleTimes;": "⊗",
            "&ClockwiseContourIntegral;": "∲",
            "&CloseCurlyDoubleQuote;": "”",
            "&CloseCurlyQuote;": "’",
            "&Colon;": "∷",
            "&Colone;": "⩴",
            "&Congruent;": "≡",
            "&Conint;": "∯",
            "&ContourIntegral;": "∮",
            "&Copf;": "ℂ",
            "&Coproduct;": "∐",
            "&CounterClockwiseContourIntegral;": "∳",
            "&Cross;": "⨯",
            "&Cscr;": "𝒞",
            "&Cup;": "⋓",
            "&CupCap;": "≍",
            "&DD;": "ⅅ",
            "&DDotrahd;": "⤑",
            "&DJcy;": "Ђ",
            "&DScy;": "Ѕ",
            "&DZcy;": "Џ",
            "&Dagger;": "‡",
            "&Darr;": "↡",
            "&Dashv;": "⫤",
            "&Dcaron;": "Ď",
            "&Dcy;": "Д",
            "&Del;": "∇",
            "&Delta;": "Δ",
            "&Dfr;": "𝔇",
            "&DiacriticalAcute;": "´",
            "&DiacriticalDot;": "˙",
            "&DiacriticalDoubleAcute;": "˝",
            "&DiacriticalGrave;": "`",
            "&DiacriticalTilde;": "˜",
            "&Diamond;": "⋄",
            "&DifferentialD;": "ⅆ",
            "&Dopf;": "𝔻",
            "&Dot;": "¨",
            "&DotDot;": "⃜",
            "&DotEqual;": "≐",
            "&DoubleContourIntegral;": "∯",
            "&DoubleDot;": "¨",
            "&DoubleDownArrow;": "⇓",
            "&DoubleLeftArrow;": "⇐",
            "&DoubleLeftRightArrow;": "⇔",
            "&DoubleLeftTee;": "⫤",
            "&DoubleLongLeftArrow;": "⟸",
            "&DoubleLongLeftRightArrow;": "⟺",
            "&DoubleLongRightArrow;": "⟹",
            "&DoubleRightArrow;": "⇒",
            "&DoubleRightTee;": "⊨",
            "&DoubleUpArrow;": "⇑",
            "&DoubleUpDownArrow;": "⇕",
            "&DoubleVerticalBar;": "∥",
            "&DownArrow;": "↓",
            "&DownArrowBar;": "⤓",
            "&DownArrowUpArrow;": "⇵",
            "&DownBreve;": "̑",
            "&DownLeftRightVector;": "⥐",
            "&DownLeftTeeVector;": "⥞",
            "&DownLeftVector;": "↽",
            "&DownLeftVectorBar;": "⥖",
            "&DownRightTeeVector;": "⥟",
            "&DownRightVector;": "⇁",
            "&DownRightVectorBar;": "⥗",
            "&DownTee;": "⊤",
            "&DownTeeArrow;": "↧",
            "&Downarrow;": "⇓",
            "&Dscr;": "𝒟",
            "&Dstrok;": "Đ",
            "&ENG;": "Ŋ",
            "&ETH": "Ð",
            "&ETH;": "Ð",
            "&Eacute": "É",
            "&Eacute;": "É",
            "&Ecaron;": "Ě",
            "&Ecirc": "Ê",
            "&Ecirc;": "Ê",
            "&Ecy;": "Э",
            "&Edot;": "Ė",
            "&Efr;": "𝔈",
            "&Egrave": "È",
            "&Egrave;": "È",
            "&Element;": "∈",
            "&Emacr;": "Ē",
            "&EmptySmallSquare;": "◻",
            "&EmptyVerySmallSquare;": "▫",
            "&Eogon;": "Ę",
            "&Eopf;": "𝔼",
            "&Epsilon;": "Ε",
            "&Equal;": "⩵",
            "&EqualTilde;": "≂",
            "&Equilibrium;": "⇌",
            "&Escr;": "ℰ",
            "&Esim;": "⩳",
            "&Eta;": "Η",
            "&Euml": "Ë",
            "&Euml;": "Ë",
            "&Exists;": "∃",
            "&ExponentialE;": "ⅇ",
            "&Fcy;": "Ф",
            "&Ffr;": "𝔉",
            "&FilledSmallSquare;": "◼",
            "&FilledVerySmallSquare;": "▪",
            "&Fopf;": "𝔽",
            "&ForAll;": "∀",
            "&Fouriertrf;": "ℱ",
            "&Fscr;": "ℱ",
            "&GJcy;": "Ѓ",
            "&GT": ">",
            "&GT;": ">",
            "&Gamma;": "Γ",
            "&Gammad;": "Ϝ",
            "&Gbreve;": "Ğ",
            "&Gcedil;": "Ģ",
            "&Gcirc;": "Ĝ",
            "&Gcy;": "Г",
            "&Gdot;": "Ġ",
            "&Gfr;": "𝔊",
            "&Gg;": "⋙",
            "&Gopf;": "𝔾",
            "&GreaterEqual;": "≥",
            "&GreaterEqualLess;": "⋛",
            "&GreaterFullEqual;": "≧",
            "&GreaterGreater;": "⪢",
            "&GreaterLess;": "≷",
            "&GreaterSlantEqual;": "⩾",
            "&GreaterTilde;": "≳",
            "&Gscr;": "𝒢",
            "&Gt;": "≫",
            "&HARDcy;": "Ъ",
            "&Hacek;": "ˇ",
            "&Hat;": "^",
            "&Hcirc;": "Ĥ",
            "&Hfr;": "ℌ",
            "&HilbertSpace;": "ℋ",
            "&Hopf;": "ℍ",
            "&HorizontalLine;": "─",
            "&Hscr;": "ℋ",
            "&Hstrok;": "Ħ",
            "&HumpDownHump;": "≎",
            "&HumpEqual;": "≏",
            "&IEcy;": "Е",
            "&IJlig;": "Ĳ",
            "&IOcy;": "Ё",
            "&Iacute": "Í",
            "&Iacute;": "Í",
            "&Icirc": "Î",
            "&Icirc;": "Î",
            "&Icy;": "И",
            "&Idot;": "İ",
            "&Ifr;": "ℑ",
            "&Igrave": "Ì",
            "&Igrave;": "Ì",
            "&Im;": "ℑ",
            "&Imacr;": "Ī",
            "&ImaginaryI;": "ⅈ",
            "&Implies;": "⇒",
            "&Int;": "∬",
            "&Integral;": "∫",
            "&Intersection;": "⋂",
            "&InvisibleComma;": "⁣",
            "&InvisibleTimes;": "⁢",
            "&Iogon;": "Į",
            "&Iopf;": "𝕀",
            "&Iota;": "Ι",
            "&Iscr;": "ℐ",
            "&Itilde;": "Ĩ",
            "&Iukcy;": "І",
            "&Iuml": "Ï",
            "&Iuml;": "Ï",
            "&Jcirc;": "Ĵ",
            "&Jcy;": "Й",
            "&Jfr;": "𝔍",
            "&Jopf;": "𝕁",
            "&Jscr;": "𝒥",
            "&Jsercy;": "Ј",
            "&Jukcy;": "Є",
            "&KHcy;": "Х",
            "&KJcy;": "Ќ",
            "&Kappa;": "Κ",
            "&Kcedil;": "Ķ",
            "&Kcy;": "К",
            "&Kfr;": "𝔎",
            "&Kopf;": "𝕂",
            "&Kscr;": "𝒦",
            "&LJcy;": "Љ",
            "&LT": "<",
            "&LT;": "<",
            "&Lacute;": "Ĺ",
            "&Lambda;": "Λ",
            "&Lang;": "⟪",
            "&Laplacetrf;": "ℒ",
            "&Larr;": "↞",
            "&Lcaron;": "Ľ",
            "&Lcedil;": "Ļ",
            "&Lcy;": "Л",
            "&LeftAngleBracket;": "⟨",
            "&LeftArrow;": "←",
            "&LeftArrowBar;": "⇤",
            "&LeftArrowRightArrow;": "⇆",
            "&LeftCeiling;": "⌈",
            "&LeftDoubleBracket;": "⟦",
            "&LeftDownTeeVector;": "⥡",
            "&LeftDownVector;": "⇃",
            "&LeftDownVectorBar;": "⥙",
            "&LeftFloor;": "⌊",
            "&LeftRightArrow;": "↔",
            "&LeftRightVector;": "⥎",
            "&LeftTee;": "⊣",
            "&LeftTeeArrow;": "↤",
            "&LeftTeeVector;": "⥚",
            "&LeftTriangle;": "⊲",
            "&LeftTriangleBar;": "⧏",
            "&LeftTriangleEqual;": "⊴",
            "&LeftUpDownVector;": "⥑",
            "&LeftUpTeeVector;": "⥠",
            "&LeftUpVector;": "↿",
            "&LeftUpVectorBar;": "⥘",
            "&LeftVector;": "↼",
            "&LeftVectorBar;": "⥒",
            "&Leftarrow;": "⇐",
            "&Leftrightarrow;": "⇔",
            "&LessEqualGreater;": "⋚",
            "&LessFullEqual;": "≦",
            "&LessGreater;": "≶",
            "&LessLess;": "⪡",
            "&LessSlantEqual;": "⩽",
            "&LessTilde;": "≲",
            "&Lfr;": "𝔏",
            "&Ll;": "⋘",
            "&Lleftarrow;": "⇚",
            "&Lmidot;": "Ŀ",
            "&LongLeftArrow;": "⟵",
            "&LongLeftRightArrow;": "⟷",
            "&LongRightArrow;": "⟶",
            "&Longleftarrow;": "⟸",
            "&Longleftrightarrow;": "⟺",
            "&Longrightarrow;": "⟹",
            "&Lopf;": "𝕃",
            "&LowerLeftArrow;": "↙",
            "&LowerRightArrow;": "↘",
            "&Lscr;": "ℒ",
            "&Lsh;": "↰",
            "&Lstrok;": "Ł",
            "&Lt;": "≪",
            "&Map;": "⤅",
            "&Mcy;": "М",
            "&MediumSpace;": " ",
            "&Mellintrf;": "ℳ",
            "&Mfr;": "𝔐",
            "&MinusPlus;": "∓",
            "&Mopf;": "𝕄",
            "&Mscr;": "ℳ",
            "&Mu;": "Μ",
            "&NJcy;": "Њ",
            "&Nacute;": "Ń",
            "&Ncaron;": "Ň",
            "&Ncedil;": "Ņ",
            "&Ncy;": "Н",
            "&NegativeMediumSpace;": "​",
            "&NegativeThickSpace;": "​",
            "&NegativeThinSpace;": "​",
            "&NegativeVeryThinSpace;": "​",
            "&NestedGreaterGreater;": "≫",
            "&NestedLessLess;": "≪",
            "&NewLine;": "\n",
            "&Nfr;": "𝔑",
            "&NoBreak;": "⁠",
            "&NonBreakingSpace;": " ",
            "&Nopf;": "ℕ",
            "&Not;": "⫬",
            "&NotCongruent;": "≢",
            "&NotCupCap;": "≭",
            "&NotDoubleVerticalBar;": "∦",
            "&NotElement;": "∉",
            "&NotEqual;": "≠",
            "&NotEqualTilde;": "≂̸",
            "&NotExists;": "∄",
            "&NotGreater;": "≯",
            "&NotGreaterEqual;": "≱",
            "&NotGreaterFullEqual;": "≧̸",
            "&NotGreaterGreater;": "≫̸",
            "&NotGreaterLess;": "≹",
            "&NotGreaterSlantEqual;": "⩾̸",
            "&NotGreaterTilde;": "≵",
            "&NotHumpDownHump;": "≎̸",
            "&NotHumpEqual;": "≏̸",
            "&NotLeftTriangle;": "⋪",
            "&NotLeftTriangleBar;": "⧏̸",
            "&NotLeftTriangleEqual;": "⋬",
            "&NotLess;": "≮",
            "&NotLessEqual;": "≰",
            "&NotLessGreater;": "≸",
            "&NotLessLess;": "≪̸",
            "&NotLessSlantEqual;": "⩽̸",
            "&NotLessTilde;": "≴",
            "&NotNestedGreaterGreater;": "⪢̸",
            "&NotNestedLessLess;": "⪡̸",
            "&NotPrecedes;": "⊀",
            "&NotPrecedesEqual;": "⪯̸",
            "&NotPrecedesSlantEqual;": "⋠",
            "&NotReverseElement;": "∌",
            "&NotRightTriangle;": "⋫",
            "&NotRightTriangleBar;": "⧐̸",
            "&NotRightTriangleEqual;": "⋭",
            "&NotSquareSubset;": "⊏̸",
            "&NotSquareSubsetEqual;": "⋢",
            "&NotSquareSuperset;": "⊐̸",
            "&NotSquareSupersetEqual;": "⋣",
            "&NotSubset;": "⊂⃒",
            "&NotSubsetEqual;": "⊈",
            "&NotSucceeds;": "⊁",
            "&NotSucceedsEqual;": "⪰̸",
            "&NotSucceedsSlantEqual;": "⋡",
            "&NotSucceedsTilde;": "≿̸",
            "&NotSuperset;": "⊃⃒",
            "&NotSupersetEqual;": "⊉",
            "&NotTilde;": "≁",
            "&NotTildeEqual;": "≄",
            "&NotTildeFullEqual;": "≇",
            "&NotTildeTilde;": "≉",
            "&NotVerticalBar;": "∤",
            "&Nscr;": "𝒩",
            "&Ntilde": "Ñ",
            "&Ntilde;": "Ñ",
            "&Nu;": "Ν",
            "&OElig;": "Œ",
            "&Oacute": "Ó",
            "&Oacute;": "Ó",
            "&Ocirc": "Ô",
            "&Ocirc;": "Ô",
            "&Ocy;": "О",
            "&Odblac;": "Ő",
            "&Ofr;": "𝔒",
            "&Ograve": "Ò",
            "&Ograve;": "Ò",
            "&Omacr;": "Ō",
            "&Omega;": "Ω",
            "&Omicron;": "Ο",
            "&Oopf;": "𝕆",
            "&OpenCurlyDoubleQuote;": "“",
            "&OpenCurlyQuote;": "‘",
            "&Or;": "⩔",
            "&Oscr;": "𝒪",
            "&Oslash": "Ø",
            "&Oslash;": "Ø",
            "&Otilde": "Õ",
            "&Otilde;": "Õ",
            "&Otimes;": "⨷",
            "&Ouml": "Ö",
            "&Ouml;": "Ö",
            "&OverBar;": "‾",
            "&OverBrace;": "⏞",
            "&OverBracket;": "⎴",
            "&OverParenthesis;": "⏜",
            "&PartialD;": "∂",
            "&Pcy;": "П",
            "&Pfr;": "𝔓",
            "&Phi;": "Φ",
            "&Pi;": "Π",
            "&PlusMinus;": "±",
            "&Poincareplane;": "ℌ",
            "&Popf;": "ℙ",
            "&Pr;": "⪻",
            "&Precedes;": "≺",
            "&PrecedesEqual;": "⪯",
            "&PrecedesSlantEqual;": "≼",
            "&PrecedesTilde;": "≾",
            "&Prime;": "″",
            "&Product;": "∏",
            "&Proportion;": "∷",
            "&Proportional;": "∝",
            "&Pscr;": "𝒫",
            "&Psi;": "Ψ",
            "&QUOT": "\"",
            "&QUOT;": "\"",
            "&Qfr;": "𝔔",
            "&Qopf;": "ℚ",
            "&Qscr;": "𝒬",
            "&RBarr;": "⤐",
            "&REG": "®",
            "&REG;": "®",
            "&Racute;": "Ŕ",
            "&Rang;": "⟫",
            "&Rarr;": "↠",
            "&Rarrtl;": "⤖",
            "&Rcaron;": "Ř",
            "&Rcedil;": "Ŗ",
            "&Rcy;": "Р",
            "&Re;": "ℜ",
            "&ReverseElement;": "∋",
            "&ReverseEquilibrium;": "⇋",
            "&ReverseUpEquilibrium;": "⥯",
            "&Rfr;": "ℜ",
            "&Rho;": "Ρ",
            "&RightAngleBracket;": "⟩",
            "&RightArrow;": "→",
            "&RightArrowBar;": "⇥",
            "&RightArrowLeftArrow;": "⇄",
            "&RightCeiling;": "⌉",
            "&RightDoubleBracket;": "⟧",
            "&RightDownTeeVector;": "⥝",
            "&RightDownVector;": "⇂",
            "&RightDownVectorBar;": "⥕",
            "&RightFloor;": "⌋",
            "&RightTee;": "⊢",
            "&RightTeeArrow;": "↦",
            "&RightTeeVector;": "⥛",
            "&RightTriangle;": "⊳",
            "&RightTriangleBar;": "⧐",
            "&RightTriangleEqual;": "⊵",
            "&RightUpDownVector;": "⥏",
            "&RightUpTeeVector;": "⥜",
            "&RightUpVector;": "↾",
            "&RightUpVectorBar;": "⥔",
            "&RightVector;": "⇀",
            "&RightVectorBar;": "⥓",
            "&Rightarrow;": "⇒",
            "&Ropf;": "ℝ",
            "&RoundImplies;": "⥰",
            "&Rrightarrow;": "⇛",
            "&Rscr;": "ℛ",
            "&Rsh;": "↱",
            "&RuleDelayed;": "⧴",
            "&SHCHcy;": "Щ",
            "&SHcy;": "Ш",
            "&SOFTcy;": "Ь",
            "&Sacute;": "Ś",
            "&Sc;": "⪼",
            "&Scaron;": "Š",
            "&Scedil;": "Ş",
            "&Scirc;": "Ŝ",
            "&Scy;": "С",
            "&Sfr;": "𝔖",
            "&ShortDownArrow;": "↓",
            "&ShortLeftArrow;": "←",
            "&ShortRightArrow;": "→",
            "&ShortUpArrow;": "↑",
            "&Sigma;": "Σ",
            "&SmallCircle;": "∘",
            "&Sopf;": "𝕊",
            "&Sqrt;": "√",
            "&Square;": "□",
            "&SquareIntersection;": "⊓",
            "&SquareSubset;": "⊏",
            "&SquareSubsetEqual;": "⊑",
            "&SquareSuperset;": "⊐",
            "&SquareSupersetEqual;": "⊒",
            "&SquareUnion;": "⊔",
            "&Sscr;": "𝒮",
            "&Star;": "⋆",
            "&Sub;": "⋐",
            "&Subset;": "⋐",
            "&SubsetEqual;": "⊆",
            "&Succeeds;": "≻",
            "&SucceedsEqual;": "⪰",
            "&SucceedsSlantEqual;": "≽",
            "&SucceedsTilde;": "≿",
            "&SuchThat;": "∋",
            "&Sum;": "∑",
            "&Sup;": "⋑",
            "&Superset;": "⊃",
            "&SupersetEqual;": "⊇",
            "&Supset;": "⋑",
            "&THORN": "Þ",
            "&THORN;": "Þ",
            "&TRADE;": "™",
            "&TSHcy;": "Ћ",
            "&TScy;": "Ц",
            "&Tab;": "\t",
            "&Tau;": "Τ",
            "&Tcaron;": "Ť",
            "&Tcedil;": "Ţ",
            "&Tcy;": "Т",
            "&Tfr;": "𝔗",
            "&Therefore;": "∴",
            "&Theta;": "Θ",
            "&ThickSpace;": "  ",
            "&ThinSpace;": " ",
            "&Tilde;": "∼",
            "&TildeEqual;": "≃",
            "&TildeFullEqual;": "≅",
            "&TildeTilde;": "≈",
            "&Topf;": "𝕋",
            "&TripleDot;": "⃛",
            "&Tscr;": "𝒯",
            "&Tstrok;": "Ŧ",
            "&Uacute": "Ú",
            "&Uacute;": "Ú",
            "&Uarr;": "↟",
            "&Uarrocir;": "⥉",
            "&Ubrcy;": "Ў",
            "&Ubreve;": "Ŭ",
            "&Ucirc": "Û",
            "&Ucirc;": "Û",
            "&Ucy;": "У",
            "&Udblac;": "Ű",
            "&Ufr;": "𝔘",
            "&Ugrave": "Ù",
            "&Ugrave;": "Ù",
            "&Umacr;": "Ū",
            "&UnderBar;": "_",
            "&UnderBrace;": "⏟",
            "&UnderBracket;": "⎵",
            "&UnderParenthesis;": "⏝",
            "&Union;": "⋃",
            "&UnionPlus;": "⊎",
            "&Uogon;": "Ų",
            "&Uopf;": "𝕌",
            "&UpArrow;": "↑",
            "&UpArrowBar;": "⤒",
            "&UpArrowDownArrow;": "⇅",
            "&UpDownArrow;": "↕",
            "&UpEquilibrium;": "⥮",
            "&UpTee;": "⊥",
            "&UpTeeArrow;": "↥",
            "&Uparrow;": "⇑",
            "&Updownarrow;": "⇕",
            "&UpperLeftArrow;": "↖",
            "&UpperRightArrow;": "↗",
            "&Upsi;": "ϒ",
            "&Upsilon;": "Υ",
            "&Uring;": "Ů",
            "&Uscr;": "𝒰",
            "&Utilde;": "Ũ",
            "&Uuml": "Ü",
            "&Uuml;": "Ü",
            "&VDash;": "⊫",
            "&Vbar;": "⫫",
            "&Vcy;": "В",
            "&Vdash;": "⊩",
            "&Vdashl;": "⫦",
            "&Vee;": "⋁",
            "&Verbar;": "‖",
            "&Vert;": "‖",
            "&VerticalBar;": "∣",
            "&VerticalLine;": "|",
            "&VerticalSeparator;": "❘",
            "&VerticalTilde;": "≀",
            "&VeryThinSpace;": " ",
            "&Vfr;": "𝔙",
            "&Vopf;": "𝕍",
            "&Vscr;": "𝒱",
            "&Vvdash;": "⊪",
            "&Wcirc;": "Ŵ",
            "&Wedge;": "⋀",
            "&Wfr;": "𝔚",
            "&Wopf;": "𝕎",
            "&Wscr;": "𝒲",
            "&Xfr;": "𝔛",
            "&Xi;": "Ξ",
            "&Xopf;": "𝕏",
            "&Xscr;": "𝒳",
            "&YAcy;": "Я",
            "&YIcy;": "Ї",
            "&YUcy;": "Ю",
            "&Yacute": "Ý",
            "&Yacute;": "Ý",
            "&Ycirc;": "Ŷ",
            "&Ycy;": "Ы",
            "&Yfr;": "𝔜",
            "&Yopf;": "𝕐",
            "&Yscr;": "𝒴",
            "&Yuml;": "Ÿ",
            "&ZHcy;": "Ж",
            "&Zacute;": "Ź",
            "&Zcaron;": "Ž",
            "&Zcy;": "З",
            "&Zdot;": "Ż",
            "&ZeroWidthSpace;": "​",
            "&Zeta;": "Ζ",
            "&Zfr;": "ℨ",
            "&Zopf;": "ℤ",
            "&Zscr;": "𝒵",
            "&aacute": "á",
            "&aacute;": "á",
            "&abreve;": "ă",
            "&ac;": "∾",
            "&acE;": "∾̳",
            "&acd;": "∿",
            "&acirc": "â",
            "&acirc;": "â",
            "&acute": "´",
            "&acute;": "´",
            "&acy;": "а",
            "&aelig": "æ",
            "&aelig;": "æ",
            "&af;": "⁡",
            "&afr;": "𝔞",
            "&agrave": "à",
            "&agrave;": "à",
            "&alefsym;": "ℵ",
            "&aleph;": "ℵ",
            "&alpha;": "α",
            "&amacr;": "ā",
            "&amalg;": "⨿",
            "&amp": "&",
            "&amp;": "&",
            "&and;": "∧",
            "&andand;": "⩕",
            "&andd;": "⩜",
            "&andslope;": "⩘",
            "&andv;": "⩚",
            "&ang;": "∠",
            "&ange;": "⦤",
            "&angle;": "∠",
            "&angmsd;": "∡",
            "&angmsdaa;": "⦨",
            "&angmsdab;": "⦩",
            "&angmsdac;": "⦪",
            "&angmsdad;": "⦫",
            "&angmsdae;": "⦬",
            "&angmsdaf;": "⦭",
            "&angmsdag;": "⦮",
            "&angmsdah;": "⦯",
            "&angrt;": "∟",
            "&angrtvb;": "⊾",
            "&angrtvbd;": "⦝",
            "&angsph;": "∢",
            "&angst;": "Å",
            "&angzarr;": "⍼",
            "&aogon;": "ą",
            "&aopf;": "𝕒",
            "&ap;": "≈",
            "&apE;": "⩰",
            "&apacir;": "⩯",
            "&ape;": "≊",
            "&apid;": "≋",
            "&apos;": "'",
            "&approx;": "≈",
            "&approxeq;": "≊",
            "&aring": "å",
            "&aring;": "å",
            "&ascr;": "𝒶",
            "&ast;": "*",
            "&asymp;": "≈",
            "&asympeq;": "≍",
            "&atilde": "ã",
            "&atilde;": "ã",
            "&auml": "ä",
            "&auml;": "ä",
            "&awconint;": "∳",
            "&awint;": "⨑",
            "&bNot;": "⫭",
            "&backcong;": "≌",
            "&backepsilon;": "϶",
            "&backprime;": "‵",
            "&backsim;": "∽",
            "&backsimeq;": "⋍",
            "&barvee;": "⊽",
            "&barwed;": "⌅",
            "&barwedge;": "⌅",
            "&bbrk;": "⎵",
            "&bbrktbrk;": "⎶",
            "&bcong;": "≌",
            "&bcy;": "б",
            "&bdquo;": "„",
            "&becaus;": "∵",
            "&because;": "∵",
            "&bemptyv;": "⦰",
            "&bepsi;": "϶",
            "&bernou;": "ℬ",
            "&beta;": "β",
            "&beth;": "ℶ",
            "&between;": "≬",
            "&bfr;": "𝔟",
            "&bigcap;": "⋂",
            "&bigcirc;": "◯",
            "&bigcup;": "⋃",
            "&bigodot;": "⨀",
            "&bigoplus;": "⨁",
            "&bigotimes;": "⨂",
            "&bigsqcup;": "⨆",
            "&bigstar;": "★",
            "&bigtriangledown;": "▽",
            "&bigtriangleup;": "△",
            "&biguplus;": "⨄",
            "&bigvee;": "⋁",
            "&bigwedge;": "⋀",
            "&bkarow;": "⤍",
            "&blacklozenge;": "⧫",
            "&blacksquare;": "▪",
            "&blacktriangle;": "▴",
            "&blacktriangledown;": "▾",
            "&blacktriangleleft;": "◂",
            "&blacktriangleright;": "▸",
            "&blank;": "␣",
            "&blk12;": "▒",
            "&blk14;": "░",
            "&blk34;": "▓",
            "&block;": "█",
            "&bne;": "=⃥",
            "&bnequiv;": "≡⃥",
            "&bnot;": "⌐",
            "&bopf;": "𝕓",
            "&bot;": "⊥",
            "&bottom;": "⊥",
            "&bowtie;": "⋈",
            "&boxDL;": "╗",
            "&boxDR;": "╔",
            "&boxDl;": "╖",
            "&boxDr;": "╓",
            "&boxH;": "═",
            "&boxHD;": "╦",
            "&boxHU;": "╩",
            "&boxHd;": "╤",
            "&boxHu;": "╧",
            "&boxUL;": "╝",
            "&boxUR;": "╚",
            "&boxUl;": "╜",
            "&boxUr;": "╙",
            "&boxV;": "║",
            "&boxVH;": "╬",
            "&boxVL;": "╣",
            "&boxVR;": "╠",
            "&boxVh;": "╫",
            "&boxVl;": "╢",
            "&boxVr;": "╟",
            "&boxbox;": "⧉",
            "&boxdL;": "╕",
            "&boxdR;": "╒",
            "&boxdl;": "┐",
            "&boxdr;": "┌",
            "&boxh;": "─",
            "&boxhD;": "╥",
            "&boxhU;": "╨",
            "&boxhd;": "┬",
            "&boxhu;": "┴",
            "&boxminus;": "⊟",
            "&boxplus;": "⊞",
            "&boxtimes;": "⊠",
            "&boxuL;": "╛",
            "&boxuR;": "╘",
            "&boxul;": "┘",
            "&boxur;": "└",
            "&boxv;": "│",
            "&boxvH;": "╪",
            "&boxvL;": "╡",
            "&boxvR;": "╞",
            "&boxvh;": "┼",
            "&boxvl;": "┤",
            "&boxvr;": "├",
            "&bprime;": "‵",
            "&breve;": "˘",
            "&brvbar": "¦",
            "&brvbar;": "¦",
            "&bscr;": "𝒷",
            "&bsemi;": "⁏",
            "&bsim;": "∽",
            "&bsime;": "⋍",
            "&bsol;": "\\",
            "&bsolb;": "⧅",
            "&bsolhsub;": "⟈",
            "&bull;": "•",
            "&bullet;": "•",
            "&bump;": "≎",
            "&bumpE;": "⪮",
            "&bumpe;": "≏",
            "&bumpeq;": "≏",
            "&cacute;": "ć",
            "&cap;": "∩",
            "&capand;": "⩄",
            "&capbrcup;": "⩉",
            "&capcap;": "⩋",
            "&capcup;": "⩇",
            "&capdot;": "⩀",
            "&caps;": "∩︀",
            "&caret;": "⁁",
            "&caron;": "ˇ",
            "&ccaps;": "⩍",
            "&ccaron;": "č",
            "&ccedil": "ç",
            "&ccedil;": "ç",
            "&ccirc;": "ĉ",
            "&ccups;": "⩌",
            "&ccupssm;": "⩐",
            "&cdot;": "ċ",
            "&cedil": "¸",
            "&cedil;": "¸",
            "&cemptyv;": "⦲",
            "&cent": "¢",
            "&cent;": "¢",
            "&centerdot;": "·",
            "&cfr;": "𝔠",
            "&chcy;": "ч",
            "&check;": "✓",
            "&checkmark;": "✓",
            "&chi;": "χ",
            "&cir;": "○",
            "&cirE;": "⧃",
            "&circ;": "ˆ",
            "&circeq;": "≗",
            "&circlearrowleft;": "↺",
            "&circlearrowright;": "↻",
            "&circledR;": "®",
            "&circledS;": "Ⓢ",
            "&circledast;": "⊛",
            "&circledcirc;": "⊚",
            "&circleddash;": "⊝",
            "&cire;": "≗",
            "&cirfnint;": "⨐",
            "&cirmid;": "⫯",
            "&cirscir;": "⧂",
            "&clubs;": "♣",
            "&clubsuit;": "♣",
            "&colon;": ":",
            "&colone;": "≔",
            "&coloneq;": "≔",
            "&comma;": ",",
            "&commat;": "@",
            "&comp;": "∁",
            "&compfn;": "∘",
            "&complement;": "∁",
            "&complexes;": "ℂ",
            "&cong;": "≅",
            "&congdot;": "⩭",
            "&conint;": "∮",
            "&copf;": "𝕔",
            "&coprod;": "∐",
            "&copy": "©",
            "&copy;": "©",
            "&copysr;": "℗",
            "&crarr;": "↵",
            "&cross;": "✗",
            "&cscr;": "𝒸",
            "&csub;": "⫏",
            "&csube;": "⫑",
            "&csup;": "⫐",
            "&csupe;": "⫒",
            "&ctdot;": "⋯",
            "&cudarrl;": "⤸",
            "&cudarrr;": "⤵",
            "&cuepr;": "⋞",
            "&cuesc;": "⋟",
            "&cularr;": "↶",
            "&cularrp;": "⤽",
            "&cup;": "∪",
            "&cupbrcap;": "⩈",
            "&cupcap;": "⩆",
            "&cupcup;": "⩊",
            "&cupdot;": "⊍",
            "&cupor;": "⩅",
            "&cups;": "∪︀",
            "&curarr;": "↷",
            "&curarrm;": "⤼",
            "&curlyeqprec;": "⋞",
            "&curlyeqsucc;": "⋟",
            "&curlyvee;": "⋎",
            "&curlywedge;": "⋏",
            "&curren": "¤",
            "&curren;": "¤",
            "&curvearrowleft;": "↶",
            "&curvearrowright;": "↷",
            "&cuvee;": "⋎",
            "&cuwed;": "⋏",
            "&cwconint;": "∲",
            "&cwint;": "∱",
            "&cylcty;": "⌭",
            "&dArr;": "⇓",
            "&dHar;": "⥥",
            "&dagger;": "†",
            "&daleth;": "ℸ",
            "&darr;": "↓",
            "&dash;": "‐",
            "&dashv;": "⊣",
            "&dbkarow;": "⤏",
            "&dblac;": "˝",
            "&dcaron;": "ď",
            "&dcy;": "д",
            "&dd;": "ⅆ",
            "&ddagger;": "‡",
            "&ddarr;": "⇊",
            "&ddotseq;": "⩷",
            "&deg": "°",
            "&deg;": "°",
            "&delta;": "δ",
            "&demptyv;": "⦱",
            "&dfisht;": "⥿",
            "&dfr;": "𝔡",
            "&dharl;": "⇃",
            "&dharr;": "⇂",
            "&diam;": "⋄",
            "&diamond;": "⋄",
            "&diamondsuit;": "♦",
            "&diams;": "♦",
            "&die;": "¨",
            "&digamma;": "ϝ",
            "&disin;": "⋲",
            "&div;": "÷",
            "&divide": "÷",
            "&divide;": "÷",
            "&divideontimes;": "⋇",
            "&divonx;": "⋇",
            "&djcy;": "ђ",
            "&dlcorn;": "⌞",
            "&dlcrop;": "⌍",
            "&dollar;": "$",
            "&dopf;": "𝕕",
            "&dot;": "˙",
            "&doteq;": "≐",
            "&doteqdot;": "≑",
            "&dotminus;": "∸",
            "&dotplus;": "∔",
            "&dotsquare;": "⊡",
            "&doublebarwedge;": "⌆",
            "&downarrow;": "↓",
            "&downdownarrows;": "⇊",
            "&downharpoonleft;": "⇃",
            "&downharpoonright;": "⇂",
            "&drbkarow;": "⤐",
            "&drcorn;": "⌟",
            "&drcrop;": "⌌",
            "&dscr;": "𝒹",
            "&dscy;": "ѕ",
            "&dsol;": "⧶",
            "&dstrok;": "đ",
            "&dtdot;": "⋱",
            "&dtri;": "▿",
            "&dtrif;": "▾",
            "&duarr;": "⇵",
            "&duhar;": "⥯",
            "&dwangle;": "⦦",
            "&dzcy;": "џ",
            "&dzigrarr;": "⟿",
            "&eDDot;": "⩷",
            "&eDot;": "≑",
            "&eacute": "é",
            "&eacute;": "é",
            "&easter;": "⩮",
            "&ecaron;": "ě",
            "&ecir;": "≖",
            "&ecirc": "ê",
            "&ecirc;": "ê",
            "&ecolon;": "≕",
            "&ecy;": "э",
            "&edot;": "ė",
            "&ee;": "ⅇ",
            "&efDot;": "≒",
            "&efr;": "𝔢",
            "&eg;": "⪚",
            "&egrave": "è",
            "&egrave;": "è",
            "&egs;": "⪖",
            "&egsdot;": "⪘",
            "&el;": "⪙",
            "&elinters;": "⏧",
            "&ell;": "ℓ",
            "&els;": "⪕",
            "&elsdot;": "⪗",
            "&emacr;": "ē",
            "&empty;": "∅",
            "&emptyset;": "∅",
            "&emptyv;": "∅",
            "&emsp13;": " ",
            "&emsp14;": " ",
            "&emsp;": " ",
            "&eng;": "ŋ",
            "&ensp;": " ",
            "&eogon;": "ę",
            "&eopf;": "𝕖",
            "&epar;": "⋕",
            "&eparsl;": "⧣",
            "&eplus;": "⩱",
            "&epsi;": "ε",
            "&epsilon;": "ε",
            "&epsiv;": "ϵ",
            "&eqcirc;": "≖",
            "&eqcolon;": "≕",
            "&eqsim;": "≂",
            "&eqslantgtr;": "⪖",
            "&eqslantless;": "⪕",
            "&equals;": "=",
            "&equest;": "≟",
            "&equiv;": "≡",
            "&equivDD;": "⩸",
            "&eqvparsl;": "⧥",
            "&erDot;": "≓",
            "&erarr;": "⥱",
            "&escr;": "ℯ",
            "&esdot;": "≐",
            "&esim;": "≂",
            "&eta;": "η",
            "&eth": "ð",
            "&eth;": "ð",
            "&euml": "ë",
            "&euml;": "ë",
            "&euro;": "€",
            "&excl;": "!",
            "&exist;": "∃",
            "&expectation;": "ℰ",
            "&exponentiale;": "ⅇ",
            "&fallingdotseq;": "≒",
            "&fcy;": "ф",
            "&female;": "♀",
            "&ffilig;": "ﬃ",
            "&fflig;": "ﬀ",
            "&ffllig;": "ﬄ",
            "&ffr;": "𝔣",
            "&filig;": "ﬁ",
            "&fjlig;": "fj",
            "&flat;": "♭",
            "&fllig;": "ﬂ",
            "&fltns;": "▱",
            "&fnof;": "ƒ",
            "&fopf;": "𝕗",
            "&forall;": "∀",
            "&fork;": "⋔",
            "&forkv;": "⫙",
            "&fpartint;": "⨍",
            "&frac12": "½",
            "&frac12;": "½",
            "&frac13;": "⅓",
            "&frac14": "¼",
            "&frac14;": "¼",
            "&frac15;": "⅕",
            "&frac16;": "⅙",
            "&frac18;": "⅛",
            "&frac23;": "⅔",
            "&frac25;": "⅖",
            "&frac34": "¾",
            "&frac34;": "¾",
            "&frac35;": "⅗",
            "&frac38;": "⅜",
            "&frac45;": "⅘",
            "&frac56;": "⅚",
            "&frac58;": "⅝",
            "&frac78;": "⅞",
            "&frasl;": "⁄",
            "&frown;": "⌢",
            "&fscr;": "𝒻",
            "&gE;": "≧",
            "&gEl;": "⪌",
            "&gacute;": "ǵ",
            "&gamma;": "γ",
            "&gammad;": "ϝ",
            "&gap;": "⪆",
            "&gbreve;": "ğ",
            "&gcirc;": "ĝ",
            "&gcy;": "г",
            "&gdot;": "ġ",
            "&ge;": "≥",
            "&gel;": "⋛",
            "&geq;": "≥",
            "&geqq;": "≧",
            "&geqslant;": "⩾",
            "&ges;": "⩾",
            "&gescc;": "⪩",
            "&gesdot;": "⪀",
            "&gesdoto;": "⪂",
            "&gesdotol;": "⪄",
            "&gesl;": "⋛︀",
            "&gesles;": "⪔",
            "&gfr;": "𝔤",
            "&gg;": "≫",
            "&ggg;": "⋙",
            "&gimel;": "ℷ",
            "&gjcy;": "ѓ",
            "&gl;": "≷",
            "&glE;": "⪒",
            "&gla;": "⪥",
            "&glj;": "⪤",
            "&gnE;": "≩",
            "&gnap;": "⪊",
            "&gnapprox;": "⪊",
            "&gne;": "⪈",
            "&gneq;": "⪈",
            "&gneqq;": "≩",
            "&gnsim;": "⋧",
            "&gopf;": "𝕘",
            "&grave;": "`",
            "&gscr;": "ℊ",
            "&gsim;": "≳",
            "&gsime;": "⪎",
            "&gsiml;": "⪐",
            "&gt": ">",
            "&gt;": ">",
            "&gtcc;": "⪧",
            "&gtcir;": "⩺",
            "&gtdot;": "⋗",
            "&gtlPar;": "⦕",
            "&gtquest;": "⩼",
            "&gtrapprox;": "⪆",
            "&gtrarr;": "⥸",
            "&gtrdot;": "⋗",
            "&gtreqless;": "⋛",
            "&gtreqqless;": "⪌",
            "&gtrless;": "≷",
            "&gtrsim;": "≳",
            "&gvertneqq;": "≩︀",
            "&gvnE;": "≩︀",
            "&hArr;": "⇔",
            "&hairsp;": " ",
            "&half;": "½",
            "&hamilt;": "ℋ",
            "&hardcy;": "ъ",
            "&harr;": "↔",
            "&harrcir;": "⥈",
            "&harrw;": "↭",
            "&hbar;": "ℏ",
            "&hcirc;": "ĥ",
            "&hearts;": "♥",
            "&heartsuit;": "♥",
            "&hellip;": "…",
            "&hercon;": "⊹",
            "&hfr;": "𝔥",
            "&hksearow;": "⤥",
            "&hkswarow;": "⤦",
            "&hoarr;": "⇿",
            "&homtht;": "∻",
            "&hookleftarrow;": "↩",
            "&hookrightarrow;": "↪",
            "&hopf;": "𝕙",
            "&horbar;": "―",
            "&hscr;": "𝒽",
            "&hslash;": "ℏ",
            "&hstrok;": "ħ",
            "&hybull;": "⁃",
            "&hyphen;": "‐",
            "&iacute": "í",
            "&iacute;": "í",
            "&ic;": "⁣",
            "&icirc": "î",
            "&icirc;": "î",
            "&icy;": "и",
            "&iecy;": "е",
            "&iexcl": "¡",
            "&iexcl;": "¡",
            "&iff;": "⇔",
            "&ifr;": "𝔦",
            "&igrave": "ì",
            "&igrave;": "ì",
            "&ii;": "ⅈ",
            "&iiiint;": "⨌",
            "&iiint;": "∭",
            "&iinfin;": "⧜",
            "&iiota;": "℩",
            "&ijlig;": "ĳ",
            "&imacr;": "ī",
            "&image;": "ℑ",
            "&imagline;": "ℐ",
            "&imagpart;": "ℑ",
            "&imath;": "ı",
            "&imof;": "⊷",
            "&imped;": "Ƶ",
            "&in;": "∈",
            "&incare;": "℅",
            "&infin;": "∞",
            "&infintie;": "⧝",
            "&inodot;": "ı",
            "&int;": "∫",
            "&intcal;": "⊺",
            "&integers;": "ℤ",
            "&intercal;": "⊺",
            "&intlarhk;": "⨗",
            "&intprod;": "⨼",
            "&iocy;": "ё",
            "&iogon;": "į",
            "&iopf;": "𝕚",
            "&iota;": "ι",
            "&iprod;": "⨼",
            "&iquest": "¿",
            "&iquest;": "¿",
            "&iscr;": "𝒾",
            "&isin;": "∈",
            "&isinE;": "⋹",
            "&isindot;": "⋵",
            "&isins;": "⋴",
            "&isinsv;": "⋳",
            "&isinv;": "∈",
            "&it;": "⁢",
            "&itilde;": "ĩ",
            "&iukcy;": "і",
            "&iuml": "ï",
            "&iuml;": "ï",
            "&jcirc;": "ĵ",
            "&jcy;": "й",
            "&jfr;": "𝔧",
            "&jmath;": "ȷ",
            "&jopf;": "𝕛",
            "&jscr;": "𝒿",
            "&jsercy;": "ј",
            "&jukcy;": "є",
            "&kappa;": "κ",
            "&kappav;": "ϰ",
            "&kcedil;": "ķ",
            "&kcy;": "к",
            "&kfr;": "𝔨",
            "&kgreen;": "ĸ",
            "&khcy;": "х",
            "&kjcy;": "ќ",
            "&kopf;": "𝕜",
            "&kscr;": "𝓀",
            "&lAarr;": "⇚",
            "&lArr;": "⇐",
            "&lAtail;": "⤛",
            "&lBarr;": "⤎",
            "&lE;": "≦",
            "&lEg;": "⪋",
            "&lHar;": "⥢",
            "&lacute;": "ĺ",
            "&laemptyv;": "⦴",
            "&lagran;": "ℒ",
            "&lambda;": "λ",
            "&lang;": "⟨",
            "&langd;": "⦑",
            "&langle;": "⟨",
            "&lap;": "⪅",
            "&laquo": "«",
            "&laquo;": "«",
            "&larr;": "←",
            "&larrb;": "⇤",
            "&larrbfs;": "⤟",
            "&larrfs;": "⤝",
            "&larrhk;": "↩",
            "&larrlp;": "↫",
            "&larrpl;": "⤹",
            "&larrsim;": "⥳",
            "&larrtl;": "↢",
            "&lat;": "⪫",
            "&latail;": "⤙",
            "&late;": "⪭",
            "&lates;": "⪭︀",
            "&lbarr;": "⤌",
            "&lbbrk;": "❲",
            "&lbrace;": "{",
            "&lbrack;": "[",
            "&lbrke;": "⦋",
            "&lbrksld;": "⦏",
            "&lbrkslu;": "⦍",
            "&lcaron;": "ľ",
            "&lcedil;": "ļ",
            "&lceil;": "⌈",
            "&lcub;": "{",
            "&lcy;": "л",
            "&ldca;": "⤶",
            "&ldquo;": "“",
            "&ldquor;": "„",
            "&ldrdhar;": "⥧",
            "&ldrushar;": "⥋",
            "&ldsh;": "↲",
            "&le;": "≤",
            "&leftarrow;": "←",
            "&leftarrowtail;": "↢",
            "&leftharpoondown;": "↽",
            "&leftharpoonup;": "↼",
            "&leftleftarrows;": "⇇",
            "&leftrightarrow;": "↔",
            "&leftrightarrows;": "⇆",
            "&leftrightharpoons;": "⇋",
            "&leftrightsquigarrow;": "↭",
            "&leftthreetimes;": "⋋",
            "&leg;": "⋚",
            "&leq;": "≤",
            "&leqq;": "≦",
            "&leqslant;": "⩽",
            "&les;": "⩽",
            "&lescc;": "⪨",
            "&lesdot;": "⩿",
            "&lesdoto;": "⪁",
            "&lesdotor;": "⪃",
            "&lesg;": "⋚︀",
            "&lesges;": "⪓",
            "&lessapprox;": "⪅",
            "&lessdot;": "⋖",
            "&lesseqgtr;": "⋚",
            "&lesseqqgtr;": "⪋",
            "&lessgtr;": "≶",
            "&lesssim;": "≲",
            "&lfisht;": "⥼",
            "&lfloor;": "⌊",
            "&lfr;": "𝔩",
            "&lg;": "≶",
            "&lgE;": "⪑",
            "&lhard;": "↽",
            "&lharu;": "↼",
            "&lharul;": "⥪",
            "&lhblk;": "▄",
            "&ljcy;": "љ",
            "&ll;": "≪",
            "&llarr;": "⇇",
            "&llcorner;": "⌞",
            "&llhard;": "⥫",
            "&lltri;": "◺",
            "&lmidot;": "ŀ",
            "&lmoust;": "⎰",
            "&lmoustache;": "⎰",
            "&lnE;": "≨",
            "&lnap;": "⪉",
            "&lnapprox;": "⪉",
            "&lne;": "⪇",
            "&lneq;": "⪇",
            "&lneqq;": "≨",
            "&lnsim;": "⋦",
            "&loang;": "⟬",
            "&loarr;": "⇽",
            "&lobrk;": "⟦",
            "&longleftarrow;": "⟵",
            "&longleftrightarrow;": "⟷",
            "&longmapsto;": "⟼",
            "&longrightarrow;": "⟶",
            "&looparrowleft;": "↫",
            "&looparrowright;": "↬",
            "&lopar;": "⦅",
            "&lopf;": "𝕝",
            "&loplus;": "⨭",
            "&lotimes;": "⨴",
            "&lowast;": "∗",
            "&lowbar;": "_",
            "&loz;": "◊",
            "&lozenge;": "◊",
            "&lozf;": "⧫",
            "&lpar;": "(",
            "&lparlt;": "⦓",
            "&lrarr;": "⇆",
            "&lrcorner;": "⌟",
            "&lrhar;": "⇋",
            "&lrhard;": "⥭",
            "&lrm;": "‎",
            "&lrtri;": "⊿",
            "&lsaquo;": "‹",
            "&lscr;": "𝓁",
            "&lsh;": "↰",
            "&lsim;": "≲",
            "&lsime;": "⪍",
            "&lsimg;": "⪏",
            "&lsqb;": "[",
            "&lsquo;": "‘",
            "&lsquor;": "‚",
            "&lstrok;": "ł",
            "&lt": "<",
            "&lt;": "<",
            "&ltcc;": "⪦",
            "&ltcir;": "⩹",
            "&ltdot;": "⋖",
            "&lthree;": "⋋",
            "&ltimes;": "⋉",
            "&ltlarr;": "⥶",
            "&ltquest;": "⩻",
            "&ltrPar;": "⦖",
            "&ltri;": "◃",
            "&ltrie;": "⊴",
            "&ltrif;": "◂",
            "&lurdshar;": "⥊",
            "&luruhar;": "⥦",
            "&lvertneqq;": "≨︀",
            "&lvnE;": "≨︀",
            "&mDDot;": "∺",
            "&macr": "¯",
            "&macr;": "¯",
            "&male;": "♂",
            "&malt;": "✠",
            "&maltese;": "✠",
            "&map;": "↦",
            "&mapsto;": "↦",
            "&mapstodown;": "↧",
            "&mapstoleft;": "↤",
            "&mapstoup;": "↥",
            "&marker;": "▮",
            "&mcomma;": "⨩",
            "&mcy;": "м",
            "&mdash;": "—",
            "&measuredangle;": "∡",
            "&mfr;": "𝔪",
            "&mho;": "℧",
            "&micro": "µ",
            "&micro;": "µ",
            "&mid;": "∣",
            "&midast;": "*",
            "&midcir;": "⫰",
            "&middot": "·",
            "&middot;": "·",
            "&minus;": "−",
            "&minusb;": "⊟",
            "&minusd;": "∸",
            "&minusdu;": "⨪",
            "&mlcp;": "⫛",
            "&mldr;": "…",
            "&mnplus;": "∓",
            "&models;": "⊧",
            "&mopf;": "𝕞",
            "&mp;": "∓",
            "&mscr;": "𝓂",
            "&mstpos;": "∾",
            "&mu;": "μ",
            "&multimap;": "⊸",
            "&mumap;": "⊸",
            "&nGg;": "⋙̸",
            "&nGt;": "≫⃒",
            "&nGtv;": "≫̸",
            "&nLeftarrow;": "⇍",
            "&nLeftrightarrow;": "⇎",
            "&nLl;": "⋘̸",
            "&nLt;": "≪⃒",
            "&nLtv;": "≪̸",
            "&nRightarrow;": "⇏",
            "&nVDash;": "⊯",
            "&nVdash;": "⊮",
            "&nabla;": "∇",
            "&nacute;": "ń",
            "&nang;": "∠⃒",
            "&nap;": "≉",
            "&napE;": "⩰̸",
            "&napid;": "≋̸",
            "&napos;": "ŉ",
            "&napprox;": "≉",
            "&natur;": "♮",
            "&natural;": "♮",
            "&naturals;": "ℕ",
            "&nbsp": " ",
            "&nbsp;": " ",
            "&nbump;": "≎̸",
            "&nbumpe;": "≏̸",
            "&ncap;": "⩃",
            "&ncaron;": "ň",
            "&ncedil;": "ņ",
            "&ncong;": "≇",
            "&ncongdot;": "⩭̸",
            "&ncup;": "⩂",
            "&ncy;": "н",
            "&ndash;": "–",
            "&ne;": "≠",
            "&neArr;": "⇗",
            "&nearhk;": "⤤",
            "&nearr;": "↗",
            "&nearrow;": "↗",
            "&nedot;": "≐̸",
            "&nequiv;": "≢",
            "&nesear;": "⤨",
            "&nesim;": "≂̸",
            "&nexist;": "∄",
            "&nexists;": "∄",
            "&nfr;": "𝔫",
            "&ngE;": "≧̸",
            "&nge;": "≱",
            "&ngeq;": "≱",
            "&ngeqq;": "≧̸",
            "&ngeqslant;": "⩾̸",
            "&nges;": "⩾̸",
            "&ngsim;": "≵",
            "&ngt;": "≯",
            "&ngtr;": "≯",
            "&nhArr;": "⇎",
            "&nharr;": "↮",
            "&nhpar;": "⫲",
            "&ni;": "∋",
            "&nis;": "⋼",
            "&nisd;": "⋺",
            "&niv;": "∋",
            "&njcy;": "њ",
            "&nlArr;": "⇍",
            "&nlE;": "≦̸",
            "&nlarr;": "↚",
            "&nldr;": "‥",
            "&nle;": "≰",
            "&nleftarrow;": "↚",
            "&nleftrightarrow;": "↮",
            "&nleq;": "≰",
            "&nleqq;": "≦̸",
            "&nleqslant;": "⩽̸",
            "&nles;": "⩽̸",
            "&nless;": "≮",
            "&nlsim;": "≴",
            "&nlt;": "≮",
            "&nltri;": "⋪",
            "&nltrie;": "⋬",
            "&nmid;": "∤",
            "&nopf;": "𝕟",
            "&not": "¬",
            "&not;": "¬",
            "&notin;": "∉",
            "&notinE;": "⋹̸",
            "&notindot;": "⋵̸",
            "&notinva;": "∉",
            "&notinvb;": "⋷",
            "&notinvc;": "⋶",
            "&notni;": "∌",
            "&notniva;": "∌",
            "&notnivb;": "⋾",
            "&notnivc;": "⋽",
            "&npar;": "∦",
            "&nparallel;": "∦",
            "&nparsl;": "⫽⃥",
            "&npart;": "∂̸",
            "&npolint;": "⨔",
            "&npr;": "⊀",
            "&nprcue;": "⋠",
            "&npre;": "⪯̸",
            "&nprec;": "⊀",
            "&npreceq;": "⪯̸",
            "&nrArr;": "⇏",
            "&nrarr;": "↛",
            "&nrarrc;": "⤳̸",
            "&nrarrw;": "↝̸",
            "&nrightarrow;": "↛",
            "&nrtri;": "⋫",
            "&nrtrie;": "⋭",
            "&nsc;": "⊁",
            "&nsccue;": "⋡",
            "&nsce;": "⪰̸",
            "&nscr;": "𝓃",
            "&nshortmid;": "∤",
            "&nshortparallel;": "∦",
            "&nsim;": "≁",
            "&nsime;": "≄",
            "&nsimeq;": "≄",
            "&nsmid;": "∤",
            "&nspar;": "∦",
            "&nsqsube;": "⋢",
            "&nsqsupe;": "⋣",
            "&nsub;": "⊄",
            "&nsubE;": "⫅̸",
            "&nsube;": "⊈",
            "&nsubset;": "⊂⃒",
            "&nsubseteq;": "⊈",
            "&nsubseteqq;": "⫅̸",
            "&nsucc;": "⊁",
            "&nsucceq;": "⪰̸",
            "&nsup;": "⊅",
            "&nsupE;": "⫆̸",
            "&nsupe;": "⊉",
            "&nsupset;": "⊃⃒",
            "&nsupseteq;": "⊉",
            "&nsupseteqq;": "⫆̸",
            "&ntgl;": "≹",
            "&ntilde": "ñ",
            "&ntilde;": "ñ",
            "&ntlg;": "≸",
            "&ntriangleleft;": "⋪",
            "&ntrianglelefteq;": "⋬",
            "&ntriangleright;": "⋫",
            "&ntrianglerighteq;": "⋭",
            "&nu;": "ν",
            "&num;": "#",
            "&numero;": "№",
            "&numsp;": " ",
            "&nvDash;": "⊭",
            "&nvHarr;": "⤄",
            "&nvap;": "≍⃒",
            "&nvdash;": "⊬",
            "&nvge;": "≥⃒",
            "&nvgt;": ">⃒",
            "&nvinfin;": "⧞",
            "&nvlArr;": "⤂",
            "&nvle;": "≤⃒",
            "&nvlt;": "<⃒",
            "&nvltrie;": "⊴⃒",
            "&nvrArr;": "⤃",
            "&nvrtrie;": "⊵⃒",
            "&nvsim;": "∼⃒",
            "&nwArr;": "⇖",
            "&nwarhk;": "⤣",
            "&nwarr;": "↖",
            "&nwarrow;": "↖",
            "&nwnear;": "⤧",
            "&oS;": "Ⓢ",
            "&oacute": "ó",
            "&oacute;": "ó",
            "&oast;": "⊛",
            "&ocir;": "⊚",
            "&ocirc": "ô",
            "&ocirc;": "ô",
            "&ocy;": "о",
            "&odash;": "⊝",
            "&odblac;": "ő",
            "&odiv;": "⨸",
            "&odot;": "⊙",
            "&odsold;": "⦼",
            "&oelig;": "œ",
            "&ofcir;": "⦿",
            "&ofr;": "𝔬",
            "&ogon;": "˛",
            "&ograve": "ò",
            "&ograve;": "ò",
            "&ogt;": "⧁",
            "&ohbar;": "⦵",
            "&ohm;": "Ω",
            "&oint;": "∮",
            "&olarr;": "↺",
            "&olcir;": "⦾",
            "&olcross;": "⦻",
            "&oline;": "‾",
            "&olt;": "⧀",
            "&omacr;": "ō",
            "&omega;": "ω",
            "&omicron;": "ο",
            "&omid;": "⦶",
            "&ominus;": "⊖",
            "&oopf;": "𝕠",
            "&opar;": "⦷",
            "&operp;": "⦹",
            "&oplus;": "⊕",
            "&or;": "∨",
            "&orarr;": "↻",
            "&ord;": "⩝",
            "&order;": "ℴ",
            "&orderof;": "ℴ",
            "&ordf": "ª",
            "&ordf;": "ª",
            "&ordm": "º",
            "&ordm;": "º",
            "&origof;": "⊶",
            "&oror;": "⩖",
            "&orslope;": "⩗",
            "&orv;": "⩛",
            "&oscr;": "ℴ",
            "&oslash": "ø",
            "&oslash;": "ø",
            "&osol;": "⊘",
            "&otilde": "õ",
            "&otilde;": "õ",
            "&otimes;": "⊗",
            "&otimesas;": "⨶",
            "&ouml": "ö",
            "&ouml;": "ö",
            "&ovbar;": "⌽",
            "&par;": "∥",
            "&para": "¶",
            "&para;": "¶",
            "&parallel;": "∥",
            "&parsim;": "⫳",
            "&parsl;": "⫽",
            "&part;": "∂",
            "&pcy;": "п",
            "&percnt;": "%",
            "&period;": ".",
            "&permil;": "‰",
            "&perp;": "⊥",
            "&pertenk;": "‱",
            "&pfr;": "𝔭",
            "&phi;": "φ",
            "&phiv;": "ϕ",
            "&phmmat;": "ℳ",
            "&phone;": "☎",
            "&pi;": "π",
            "&pitchfork;": "⋔",
            "&piv;": "ϖ",
            "&planck;": "ℏ",
            "&planckh;": "ℎ",
            "&plankv;": "ℏ",
            "&plus;": "+",
            "&plusacir;": "⨣",
            "&plusb;": "⊞",
            "&pluscir;": "⨢",
            "&plusdo;": "∔",
            "&plusdu;": "⨥",
            "&pluse;": "⩲",
            "&plusmn": "±",
            "&plusmn;": "±",
            "&plussim;": "⨦",
            "&plustwo;": "⨧",
            "&pm;": "±",
            "&pointint;": "⨕",
            "&popf;": "𝕡",
            "&pound": "£",
            "&pound;": "£",
            "&pr;": "≺",
            "&prE;": "⪳",
            "&prap;": "⪷",
            "&prcue;": "≼",
            "&pre;": "⪯",
            "&prec;": "≺",
            "&precapprox;": "⪷",
            "&preccurlyeq;": "≼",
            "&preceq;": "⪯",
            "&precnapprox;": "⪹",
            "&precneqq;": "⪵",
            "&precnsim;": "⋨",
            "&precsim;": "≾",
            "&prime;": "′",
            "&primes;": "ℙ",
            "&prnE;": "⪵",
            "&prnap;": "⪹",
            "&prnsim;": "⋨",
            "&prod;": "∏",
            "&profalar;": "⌮",
            "&profline;": "⌒",
            "&profsurf;": "⌓",
            "&prop;": "∝",
            "&propto;": "∝",
            "&prsim;": "≾",
            "&prurel;": "⊰",
            "&pscr;": "𝓅",
            "&psi;": "ψ",
            "&puncsp;": " ",
            "&qfr;": "𝔮",
            "&qint;": "⨌",
            "&qopf;": "𝕢",
            "&qprime;": "⁗",
            "&qscr;": "𝓆",
            "&quaternions;": "ℍ",
            "&quatint;": "⨖",
            "&quest;": "?",
            "&questeq;": "≟",
            "&quot": "\"",
            "&quot;": "\"",
            "&rAarr;": "⇛",
            "&rArr;": "⇒",
            "&rAtail;": "⤜",
            "&rBarr;": "⤏",
            "&rHar;": "⥤",
            "&race;": "∽̱",
            "&racute;": "ŕ",
            "&radic;": "√",
            "&raemptyv;": "⦳",
            "&rang;": "⟩",
            "&rangd;": "⦒",
            "&range;": "⦥",
            "&rangle;": "⟩",
            "&raquo": "»",
            "&raquo;": "»",
            "&rarr;": "→",
            "&rarrap;": "⥵",
            "&rarrb;": "⇥",
            "&rarrbfs;": "⤠",
            "&rarrc;": "⤳",
            "&rarrfs;": "⤞",
            "&rarrhk;": "↪",
            "&rarrlp;": "↬",
            "&rarrpl;": "⥅",
            "&rarrsim;": "⥴",
            "&rarrtl;": "↣",
            "&rarrw;": "↝",
            "&ratail;": "⤚",
            "&ratio;": "∶",
            "&rationals;": "ℚ",
            "&rbarr;": "⤍",
            "&rbbrk;": "❳",
            "&rbrace;": "}",
            "&rbrack;": "]",
            "&rbrke;": "⦌",
            "&rbrksld;": "⦎",
            "&rbrkslu;": "⦐",
            "&rcaron;": "ř",
            "&rcedil;": "ŗ",
            "&rceil;": "⌉",
            "&rcub;": "}",
            "&rcy;": "р",
            "&rdca;": "⤷",
            "&rdldhar;": "⥩",
            "&rdquo;": "”",
            "&rdquor;": "”",
            "&rdsh;": "↳",
            "&real;": "ℜ",
            "&realine;": "ℛ",
            "&realpart;": "ℜ",
            "&reals;": "ℝ",
            "&rect;": "▭",
            "&reg": "®",
            "&reg;": "®",
            "&rfisht;": "⥽",
            "&rfloor;": "⌋",
            "&rfr;": "𝔯",
            "&rhard;": "⇁",
            "&rharu;": "⇀",
            "&rharul;": "⥬",
            "&rho;": "ρ",
            "&rhov;": "ϱ",
            "&rightarrow;": "→",
            "&rightarrowtail;": "↣",
            "&rightharpoondown;": "⇁",
            "&rightharpoonup;": "⇀",
            "&rightleftarrows;": "⇄",
            "&rightleftharpoons;": "⇌",
            "&rightrightarrows;": "⇉",
            "&rightsquigarrow;": "↝",
            "&rightthreetimes;": "⋌",
            "&ring;": "˚",
            "&risingdotseq;": "≓",
            "&rlarr;": "⇄",
            "&rlhar;": "⇌",
            "&rlm;": "‏",
            "&rmoust;": "⎱",
            "&rmoustache;": "⎱",
            "&rnmid;": "⫮",
            "&roang;": "⟭",
            "&roarr;": "⇾",
            "&robrk;": "⟧",
            "&ropar;": "⦆",
            "&ropf;": "𝕣",
            "&roplus;": "⨮",
            "&rotimes;": "⨵",
            "&rpar;": ")",
            "&rpargt;": "⦔",
            "&rppolint;": "⨒",
            "&rrarr;": "⇉",
            "&rsaquo;": "›",
            "&rscr;": "𝓇",
            "&rsh;": "↱",
            "&rsqb;": "]",
            "&rsquo;": "’",
            "&rsquor;": "’",
            "&rthree;": "⋌",
            "&rtimes;": "⋊",
            "&rtri;": "▹",
            "&rtrie;": "⊵",
            "&rtrif;": "▸",
            "&rtriltri;": "⧎",
            "&ruluhar;": "⥨",
            "&rx;": "℞",
            "&sacute;": "ś",
            "&sbquo;": "‚",
            "&sc;": "≻",
            "&scE;": "⪴",
            "&scap;": "⪸",
            "&scaron;": "š",
            "&sccue;": "≽",
            "&sce;": "⪰",
            "&scedil;": "ş",
            "&scirc;": "ŝ",
            "&scnE;": "⪶",
            "&scnap;": "⪺",
            "&scnsim;": "⋩",
            "&scpolint;": "⨓",
            "&scsim;": "≿",
            "&scy;": "с",
            "&sdot;": "⋅",
            "&sdotb;": "⊡",
            "&sdote;": "⩦",
            "&seArr;": "⇘",
            "&searhk;": "⤥",
            "&searr;": "↘",
            "&searrow;": "↘",
            "&sect": "§",
            "&sect;": "§",
            "&semi;": ";",
            "&seswar;": "⤩",
            "&setminus;": "∖",
            "&setmn;": "∖",
            "&sext;": "✶",
            "&sfr;": "𝔰",
            "&sfrown;": "⌢",
            "&sharp;": "♯",
            "&shchcy;": "щ",
            "&shcy;": "ш",
            "&shortmid;": "∣",
            "&shortparallel;": "∥",
            "&shy": "­",
            "&shy;": "­",
            "&sigma;": "σ",
            "&sigmaf;": "ς",
            "&sigmav;": "ς",
            "&sim;": "∼",
            "&simdot;": "⩪",
            "&sime;": "≃",
            "&simeq;": "≃",
            "&simg;": "⪞",
            "&simgE;": "⪠",
            "&siml;": "⪝",
            "&simlE;": "⪟",
            "&simne;": "≆",
            "&simplus;": "⨤",
            "&simrarr;": "⥲",
            "&slarr;": "←",
            "&smallsetminus;": "∖",
            "&smashp;": "⨳",
            "&smeparsl;": "⧤",
            "&smid;": "∣",
            "&smile;": "⌣",
            "&smt;": "⪪",
            "&smte;": "⪬",
            "&smtes;": "⪬︀",
            "&softcy;": "ь",
            "&sol;": "/",
            "&solb;": "⧄",
            "&solbar;": "⌿",
            "&sopf;": "𝕤",
            "&spades;": "♠",
            "&spadesuit;": "♠",
            "&spar;": "∥",
            "&sqcap;": "⊓",
            "&sqcaps;": "⊓︀",
            "&sqcup;": "⊔",
            "&sqcups;": "⊔︀",
            "&sqsub;": "⊏",
            "&sqsube;": "⊑",
            "&sqsubset;": "⊏",
            "&sqsubseteq;": "⊑",
            "&sqsup;": "⊐",
            "&sqsupe;": "⊒",
            "&sqsupset;": "⊐",
            "&sqsupseteq;": "⊒",
            "&squ;": "□",
            "&square;": "□",
            "&squarf;": "▪",
            "&squf;": "▪",
            "&srarr;": "→",
            "&sscr;": "𝓈",
            "&ssetmn;": "∖",
            "&ssmile;": "⌣",
            "&sstarf;": "⋆",
            "&star;": "☆",
            "&starf;": "★",
            "&straightepsilon;": "ϵ",
            "&straightphi;": "ϕ",
            "&strns;": "¯",
            "&sub;": "⊂",
            "&subE;": "⫅",
            "&subdot;": "⪽",
            "&sube;": "⊆",
            "&subedot;": "⫃",
            "&submult;": "⫁",
            "&subnE;": "⫋",
            "&subne;": "⊊",
            "&subplus;": "⪿",
            "&subrarr;": "⥹",
            "&subset;": "⊂",
            "&subseteq;": "⊆",
            "&subseteqq;": "⫅",
            "&subsetneq;": "⊊",
            "&subsetneqq;": "⫋",
            "&subsim;": "⫇",
            "&subsub;": "⫕",
            "&subsup;": "⫓",
            "&succ;": "≻",
            "&succapprox;": "⪸",
            "&succcurlyeq;": "≽",
            "&succeq;": "⪰",
            "&succnapprox;": "⪺",
            "&succneqq;": "⪶",
            "&succnsim;": "⋩",
            "&succsim;": "≿",
            "&sum;": "∑",
            "&sung;": "♪",
            "&sup1": "¹",
            "&sup1;": "¹",
            "&sup2": "²",
            "&sup2;": "²",
            "&sup3": "³",
            "&sup3;": "³",
            "&sup;": "⊃",
            "&supE;": "⫆",
            "&supdot;": "⪾",
            "&supdsub;": "⫘",
            "&supe;": "⊇",
            "&supedot;": "⫄",
            "&suphsol;": "⟉",
            "&suphsub;": "⫗",
            "&suplarr;": "⥻",
            "&supmult;": "⫂",
            "&supnE;": "⫌",
            "&supne;": "⊋",
            "&supplus;": "⫀",
            "&supset;": "⊃",
            "&supseteq;": "⊇",
            "&supseteqq;": "⫆",
            "&supsetneq;": "⊋",
            "&supsetneqq;": "⫌",
            "&supsim;": "⫈",
            "&supsub;": "⫔",
            "&supsup;": "⫖",
            "&swArr;": "⇙",
            "&swarhk;": "⤦",
            "&swarr;": "↙",
            "&swarrow;": "↙",
            "&swnwar;": "⤪",
            "&szlig": "ß",
            "&szlig;": "ß",
            "&target;": "⌖",
            "&tau;": "τ",
            "&tbrk;": "⎴",
            "&tcaron;": "ť",
            "&tcedil;": "ţ",
            "&tcy;": "т",
            "&tdot;": "⃛",
            "&telrec;": "⌕",
            "&tfr;": "𝔱",
            "&there4;": "∴",
            "&therefore;": "∴",
            "&theta;": "θ",
            "&thetasym;": "ϑ",
            "&thetav;": "ϑ",
            "&thickapprox;": "≈",
            "&thicksim;": "∼",
            "&thinsp;": " ",
            "&thkap;": "≈",
            "&thksim;": "∼",
            "&thorn": "þ",
            "&thorn;": "þ",
            "&tilde;": "˜",
            "&times": "×",
            "&times;": "×",
            "&timesb;": "⊠",
            "&timesbar;": "⨱",
            "&timesd;": "⨰",
            "&tint;": "∭",
            "&toea;": "⤨",
            "&top;": "⊤",
            "&topbot;": "⌶",
            "&topcir;": "⫱",
            "&topf;": "𝕥",
            "&topfork;": "⫚",
            "&tosa;": "⤩",
            "&tprime;": "‴",
            "&trade;": "™",
            "&triangle;": "▵",
            "&triangledown;": "▿",
            "&triangleleft;": "◃",
            "&trianglelefteq;": "⊴",
            "&triangleq;": "≜",
            "&triangleright;": "▹",
            "&trianglerighteq;": "⊵",
            "&tridot;": "◬",
            "&trie;": "≜",
            "&triminus;": "⨺",
            "&triplus;": "⨹",
            "&trisb;": "⧍",
            "&tritime;": "⨻",
            "&trpezium;": "⏢",
            "&tscr;": "𝓉",
            "&tscy;": "ц",
            "&tshcy;": "ћ",
            "&tstrok;": "ŧ",
            "&twixt;": "≬",
            "&twoheadleftarrow;": "↞",
            "&twoheadrightarrow;": "↠",
            "&uArr;": "⇑",
            "&uHar;": "⥣",
            "&uacute": "ú",
            "&uacute;": "ú",
            "&uarr;": "↑",
            "&ubrcy;": "ў",
            "&ubreve;": "ŭ",
            "&ucirc": "û",
            "&ucirc;": "û",
            "&ucy;": "у",
            "&udarr;": "⇅",
            "&udblac;": "ű",
            "&udhar;": "⥮",
            "&ufisht;": "⥾",
            "&ufr;": "𝔲",
            "&ugrave": "ù",
            "&ugrave;": "ù",
            "&uharl;": "↿",
            "&uharr;": "↾",
            "&uhblk;": "▀",
            "&ulcorn;": "⌜",
            "&ulcorner;": "⌜",
            "&ulcrop;": "⌏",
            "&ultri;": "◸",
            "&umacr;": "ū",
            "&uml": "¨",
            "&uml;": "¨",
            "&uogon;": "ų",
            "&uopf;": "𝕦",
            "&uparrow;": "↑",
            "&updownarrow;": "↕",
            "&upharpoonleft;": "↿",
            "&upharpoonright;": "↾",
            "&uplus;": "⊎",
            "&upsi;": "υ",
            "&upsih;": "ϒ",
            "&upsilon;": "υ",
            "&upuparrows;": "⇈",
            "&urcorn;": "⌝",
            "&urcorner;": "⌝",
            "&urcrop;": "⌎",
            "&uring;": "ů",
            "&urtri;": "◹",
            "&uscr;": "𝓊",
            "&utdot;": "⋰",
            "&utilde;": "ũ",
            "&utri;": "▵",
            "&utrif;": "▴",
            "&uuarr;": "⇈",
            "&uuml": "ü",
            "&uuml;": "ü",
            "&uwangle;": "⦧",
            "&vArr;": "⇕",
            "&vBar;": "⫨",
            "&vBarv;": "⫩",
            "&vDash;": "⊨",
            "&vangrt;": "⦜",
            "&varepsilon;": "ϵ",
            "&varkappa;": "ϰ",
            "&varnothing;": "∅",
            "&varphi;": "ϕ",
            "&varpi;": "ϖ",
            "&varpropto;": "∝",
            "&varr;": "↕",
            "&varrho;": "ϱ",
            "&varsigma;": "ς",
            "&varsubsetneq;": "⊊︀",
            "&varsubsetneqq;": "⫋︀",
            "&varsupsetneq;": "⊋︀",
            "&varsupsetneqq;": "⫌︀",
            "&vartheta;": "ϑ",
            "&vartriangleleft;": "⊲",
            "&vartriangleright;": "⊳",
            "&vcy;": "в",
            "&vdash;": "⊢",
            "&vee;": "∨",
            "&veebar;": "⊻",
            "&veeeq;": "≚",
            "&vellip;": "⋮",
            "&verbar;": "|",
            "&vert;": "|",
            "&vfr;": "𝔳",
            "&vltri;": "⊲",
            "&vnsub;": "⊂⃒",
            "&vnsup;": "⊃⃒",
            "&vopf;": "𝕧",
            "&vprop;": "∝",
            "&vrtri;": "⊳",
            "&vscr;": "𝓋",
            "&vsubnE;": "⫋︀",
            "&vsubne;": "⊊︀",
            "&vsupnE;": "⫌︀",
            "&vsupne;": "⊋︀",
            "&vzigzag;": "⦚",
            "&wcirc;": "ŵ",
            "&wedbar;": "⩟",
            "&wedge;": "∧",
            "&wedgeq;": "≙",
            "&weierp;": "℘",
            "&wfr;": "𝔴",
            "&wopf;": "𝕨",
            "&wp;": "℘",
            "&wr;": "≀",
            "&wreath;": "≀",
            "&wscr;": "𝓌",
            "&xcap;": "⋂",
            "&xcirc;": "◯",
            "&xcup;": "⋃",
            "&xdtri;": "▽",
            "&xfr;": "𝔵",
            "&xhArr;": "⟺",
            "&xharr;": "⟷",
            "&xi;": "ξ",
            "&xlArr;": "⟸",
            "&xlarr;": "⟵",
            "&xmap;": "⟼",
            "&xnis;": "⋻",
            "&xodot;": "⨀",
            "&xopf;": "𝕩",
            "&xoplus;": "⨁",
            "&xotime;": "⨂",
            "&xrArr;": "⟹",
            "&xrarr;": "⟶",
            "&xscr;": "𝓍",
            "&xsqcup;": "⨆",
            "&xuplus;": "⨄",
            "&xutri;": "△",
            "&xvee;": "⋁",
            "&xwedge;": "⋀",
            "&yacute": "ý",
            "&yacute;": "ý",
            "&yacy;": "я",
            "&ycirc;": "ŷ",
            "&ycy;": "ы",
            "&yen": "¥",
            "&yen;": "¥",
            "&yfr;": "𝔶",
            "&yicy;": "ї",
            "&yopf;": "𝕪",
            "&yscr;": "𝓎",
            "&yucy;": "ю",
            "&yuml": "ÿ",
            "&yuml;": "ÿ",
            "&zacute;": "ź",
            "&zcaron;": "ž",
            "&zcy;": "з",
            "&zdot;": "ż",
            "&zeetrf;": "ℨ",
            "&zeta;": "ζ",
            "&zfr;": "𝔷",
            "&zhcy;": "ж",
            "&zigrarr;": "⇝",
            "&zopf;": "𝕫",
            "&zscr;": "𝓏",
            "&zwj;": "‍",
            "&zwnj;": "‌"
        },
        "characters": {
            "Æ": "&AElig;",
            "&": "&amp;",
            "Á": "&Aacute;",
            "Ă": "&Abreve;",
            "Â": "&Acirc;",
            "А": "&Acy;",
            "𝔄": "&Afr;",
            "À": "&Agrave;",
            "Α": "&Alpha;",
            "Ā": "&Amacr;",
            "⩓": "&And;",
            "Ą": "&Aogon;",
            "𝔸": "&Aopf;",
            "⁡": "&af;",
            "Å": "&angst;",
            "𝒜": "&Ascr;",
            "≔": "&coloneq;",
            "Ã": "&Atilde;",
            "Ä": "&Auml;",
            "∖": "&ssetmn;",
            "⫧": "&Barv;",
            "⌆": "&doublebarwedge;",
            "Б": "&Bcy;",
            "∵": "&because;",
            "ℬ": "&bernou;",
            "Β": "&Beta;",
            "𝔅": "&Bfr;",
            "𝔹": "&Bopf;",
            "˘": "&breve;",
            "≎": "&bump;",
            "Ч": "&CHcy;",
            "©": "&copy;",
            "Ć": "&Cacute;",
            "⋒": "&Cap;",
            "ⅅ": "&DD;",
            "ℭ": "&Cfr;",
            "Č": "&Ccaron;",
            "Ç": "&Ccedil;",
            "Ĉ": "&Ccirc;",
            "∰": "&Cconint;",
            "Ċ": "&Cdot;",
            "¸": "&cedil;",
            "·": "&middot;",
            "Χ": "&Chi;",
            "⊙": "&odot;",
            "⊖": "&ominus;",
            "⊕": "&oplus;",
            "⊗": "&otimes;",
            "∲": "&cwconint;",
            "”": "&rdquor;",
            "’": "&rsquor;",
            "∷": "&Proportion;",
            "⩴": "&Colone;",
            "≡": "&equiv;",
            "∯": "&DoubleContourIntegral;",
            "∮": "&oint;",
            "ℂ": "&complexes;",
            "∐": "&coprod;",
            "∳": "&awconint;",
            "⨯": "&Cross;",
            "𝒞": "&Cscr;",
            "⋓": "&Cup;",
            "≍": "&asympeq;",
            "⤑": "&DDotrahd;",
            "Ђ": "&DJcy;",
            "Ѕ": "&DScy;",
            "Џ": "&DZcy;",
            "‡": "&ddagger;",
            "↡": "&Darr;",
            "⫤": "&DoubleLeftTee;",
            "Ď": "&Dcaron;",
            "Д": "&Dcy;",
            "∇": "&nabla;",
            "Δ": "&Delta;",
            "𝔇": "&Dfr;",
            "´": "&acute;",
            "˙": "&dot;",
            "˝": "&dblac;",
            "`": "&grave;",
            "˜": "&tilde;",
            "⋄": "&diamond;",
            "ⅆ": "&dd;",
            "𝔻": "&Dopf;",
            "¨": "&uml;",
            "⃜": "&DotDot;",
            "≐": "&esdot;",
            "⇓": "&dArr;",
            "⇐": "&lArr;",
            "⇔": "&iff;",
            "⟸": "&xlArr;",
            "⟺": "&xhArr;",
            "⟹": "&xrArr;",
            "⇒": "&rArr;",
            "⊨": "&vDash;",
            "⇑": "&uArr;",
            "⇕": "&vArr;",
            "∥": "&spar;",
            "↓": "&downarrow;",
            "⤓": "&DownArrowBar;",
            "⇵": "&duarr;",
            "̑": "&DownBreve;",
            "⥐": "&DownLeftRightVector;",
            "⥞": "&DownLeftTeeVector;",
            "↽": "&lhard;",
            "⥖": "&DownLeftVectorBar;",
            "⥟": "&DownRightTeeVector;",
            "⇁": "&rightharpoondown;",
            "⥗": "&DownRightVectorBar;",
            "⊤": "&top;",
            "↧": "&mapstodown;",
            "𝒟": "&Dscr;",
            "Đ": "&Dstrok;",
            "Ŋ": "&ENG;",
            "Ð": "&ETH;",
            "É": "&Eacute;",
            "Ě": "&Ecaron;",
            "Ê": "&Ecirc;",
            "Э": "&Ecy;",
            "Ė": "&Edot;",
            "𝔈": "&Efr;",
            "È": "&Egrave;",
            "∈": "&isinv;",
            "Ē": "&Emacr;",
            "◻": "&EmptySmallSquare;",
            "▫": "&EmptyVerySmallSquare;",
            "Ę": "&Eogon;",
            "𝔼": "&Eopf;",
            "Ε": "&Epsilon;",
            "⩵": "&Equal;",
            "≂": "&esim;",
            "⇌": "&rlhar;",
            "ℰ": "&expectation;",
            "⩳": "&Esim;",
            "Η": "&Eta;",
            "Ë": "&Euml;",
            "∃": "&exist;",
            "ⅇ": "&exponentiale;",
            "Ф": "&Fcy;",
            "𝔉": "&Ffr;",
            "◼": "&FilledSmallSquare;",
            "▪": "&squf;",
            "𝔽": "&Fopf;",
            "∀": "&forall;",
            "ℱ": "&Fscr;",
            "Ѓ": "&GJcy;",
            ">": "&gt;",
            "Γ": "&Gamma;",
            "Ϝ": "&Gammad;",
            "Ğ": "&Gbreve;",
            "Ģ": "&Gcedil;",
            "Ĝ": "&Gcirc;",
            "Г": "&Gcy;",
            "Ġ": "&Gdot;",
            "𝔊": "&Gfr;",
            "⋙": "&ggg;",
            "𝔾": "&Gopf;",
            "≥": "&geq;",
            "⋛": "&gtreqless;",
            "≧": "&geqq;",
            "⪢": "&GreaterGreater;",
            "≷": "&gtrless;",
            "⩾": "&ges;",
            "≳": "&gtrsim;",
            "𝒢": "&Gscr;",
            "≫": "&gg;",
            "Ъ": "&HARDcy;",
            "ˇ": "&caron;",
            "^": "&Hat;",
            "Ĥ": "&Hcirc;",
            "ℌ": "&Poincareplane;",
            "ℋ": "&hamilt;",
            "ℍ": "&quaternions;",
            "─": "&boxh;",
            "Ħ": "&Hstrok;",
            "≏": "&bumpeq;",
            "Е": "&IEcy;",
            "Ĳ": "&IJlig;",
            "Ё": "&IOcy;",
            "Í": "&Iacute;",
            "Î": "&Icirc;",
            "И": "&Icy;",
            "İ": "&Idot;",
            "ℑ": "&imagpart;",
            "Ì": "&Igrave;",
            "Ī": "&Imacr;",
            "ⅈ": "&ii;",
            "∬": "&Int;",
            "∫": "&int;",
            "⋂": "&xcap;",
            "⁣": "&ic;",
            "⁢": "&it;",
            "Į": "&Iogon;",
            "𝕀": "&Iopf;",
            "Ι": "&Iota;",
            "ℐ": "&imagline;",
            "Ĩ": "&Itilde;",
            "І": "&Iukcy;",
            "Ï": "&Iuml;",
            "Ĵ": "&Jcirc;",
            "Й": "&Jcy;",
            "𝔍": "&Jfr;",
            "𝕁": "&Jopf;",
            "𝒥": "&Jscr;",
            "Ј": "&Jsercy;",
            "Є": "&Jukcy;",
            "Х": "&KHcy;",
            "Ќ": "&KJcy;",
            "Κ": "&Kappa;",
            "Ķ": "&Kcedil;",
            "К": "&Kcy;",
            "𝔎": "&Kfr;",
            "𝕂": "&Kopf;",
            "𝒦": "&Kscr;",
            "Љ": "&LJcy;",
            "<": "&lt;",
            "Ĺ": "&Lacute;",
            "Λ": "&Lambda;",
            "⟪": "&Lang;",
            "ℒ": "&lagran;",
            "↞": "&twoheadleftarrow;",
            "Ľ": "&Lcaron;",
            "Ļ": "&Lcedil;",
            "Л": "&Lcy;",
            "⟨": "&langle;",
            "←": "&slarr;",
            "⇤": "&larrb;",
            "⇆": "&lrarr;",
            "⌈": "&lceil;",
            "⟦": "&lobrk;",
            "⥡": "&LeftDownTeeVector;",
            "⇃": "&downharpoonleft;",
            "⥙": "&LeftDownVectorBar;",
            "⌊": "&lfloor;",
            "↔": "&leftrightarrow;",
            "⥎": "&LeftRightVector;",
            "⊣": "&dashv;",
            "↤": "&mapstoleft;",
            "⥚": "&LeftTeeVector;",
            "⊲": "&vltri;",
            "⧏": "&LeftTriangleBar;",
            "⊴": "&trianglelefteq;",
            "⥑": "&LeftUpDownVector;",
            "⥠": "&LeftUpTeeVector;",
            "↿": "&upharpoonleft;",
            "⥘": "&LeftUpVectorBar;",
            "↼": "&lharu;",
            "⥒": "&LeftVectorBar;",
            "⋚": "&lesseqgtr;",
            "≦": "&leqq;",
            "≶": "&lg;",
            "⪡": "&LessLess;",
            "⩽": "&les;",
            "≲": "&lsim;",
            "𝔏": "&Lfr;",
            "⋘": "&Ll;",
            "⇚": "&lAarr;",
            "Ŀ": "&Lmidot;",
            "⟵": "&xlarr;",
            "⟷": "&xharr;",
            "⟶": "&xrarr;",
            "𝕃": "&Lopf;",
            "↙": "&swarrow;",
            "↘": "&searrow;",
            "↰": "&lsh;",
            "Ł": "&Lstrok;",
            "≪": "&ll;",
            "⤅": "&Map;",
            "М": "&Mcy;",
            " ": "&MediumSpace;",
            "ℳ": "&phmmat;",
            "𝔐": "&Mfr;",
            "∓": "&mp;",
            "𝕄": "&Mopf;",
            "Μ": "&Mu;",
            "Њ": "&NJcy;",
            "Ń": "&Nacute;",
            "Ň": "&Ncaron;",
            "Ņ": "&Ncedil;",
            "Н": "&Ncy;",
            "​": "&ZeroWidthSpace;",
            "\n": "&NewLine;",
            "𝔑": "&Nfr;",
            "⁠": "&NoBreak;",
            " ": "&nbsp;",
            "ℕ": "&naturals;",
            "⫬": "&Not;",
            "≢": "&nequiv;",
            "≭": "&NotCupCap;",
            "∦": "&nspar;",
            "∉": "&notinva;",
            "≠": "&ne;",
            "≂̸": "&nesim;",
            "∄": "&nexists;",
            "≯": "&ngtr;",
            "≱": "&ngeq;",
            "≧̸": "&ngeqq;",
            "≫̸": "&nGtv;",
            "≹": "&ntgl;",
            "⩾̸": "&nges;",
            "≵": "&ngsim;",
            "≎̸": "&nbump;",
            "≏̸": "&nbumpe;",
            "⋪": "&ntriangleleft;",
            "⧏̸": "&NotLeftTriangleBar;",
            "⋬": "&ntrianglelefteq;",
            "≮": "&nlt;",
            "≰": "&nleq;",
            "≸": "&ntlg;",
            "≪̸": "&nLtv;",
            "⩽̸": "&nles;",
            "≴": "&nlsim;",
            "⪢̸": "&NotNestedGreaterGreater;",
            "⪡̸": "&NotNestedLessLess;",
            "⊀": "&nprec;",
            "⪯̸": "&npreceq;",
            "⋠": "&nprcue;",
            "∌": "&notniva;",
            "⋫": "&ntriangleright;",
            "⧐̸": "&NotRightTriangleBar;",
            "⋭": "&ntrianglerighteq;",
            "⊏̸": "&NotSquareSubset;",
            "⋢": "&nsqsube;",
            "⊐̸": "&NotSquareSuperset;",
            "⋣": "&nsqsupe;",
            "⊂⃒": "&vnsub;",
            "⊈": "&nsubseteq;",
            "⊁": "&nsucc;",
            "⪰̸": "&nsucceq;",
            "⋡": "&nsccue;",
            "≿̸": "&NotSucceedsTilde;",
            "⊃⃒": "&vnsup;",
            "⊉": "&nsupseteq;",
            "≁": "&nsim;",
            "≄": "&nsimeq;",
            "≇": "&ncong;",
            "≉": "&napprox;",
            "∤": "&nsmid;",
            "𝒩": "&Nscr;",
            "Ñ": "&Ntilde;",
            "Ν": "&Nu;",
            "Œ": "&OElig;",
            "Ó": "&Oacute;",
            "Ô": "&Ocirc;",
            "О": "&Ocy;",
            "Ő": "&Odblac;",
            "𝔒": "&Ofr;",
            "Ò": "&Ograve;",
            "Ō": "&Omacr;",
            "Ω": "&ohm;",
            "Ο": "&Omicron;",
            "𝕆": "&Oopf;",
            "“": "&ldquo;",
            "‘": "&lsquo;",
            "⩔": "&Or;",
            "𝒪": "&Oscr;",
            "Ø": "&Oslash;",
            "Õ": "&Otilde;",
            "⨷": "&Otimes;",
            "Ö": "&Ouml;",
            "‾": "&oline;",
            "⏞": "&OverBrace;",
            "⎴": "&tbrk;",
            "⏜": "&OverParenthesis;",
            "∂": "&part;",
            "П": "&Pcy;",
            "𝔓": "&Pfr;",
            "Φ": "&Phi;",
            "Π": "&Pi;",
            "±": "&pm;",
            "ℙ": "&primes;",
            "⪻": "&Pr;",
            "≺": "&prec;",
            "⪯": "&preceq;",
            "≼": "&preccurlyeq;",
            "≾": "&prsim;",
            "″": "&Prime;",
            "∏": "&prod;",
            "∝": "&vprop;",
            "𝒫": "&Pscr;",
            "Ψ": "&Psi;",
            "\"": "&quot;",
            "𝔔": "&Qfr;",
            "ℚ": "&rationals;",
            "𝒬": "&Qscr;",
            "⤐": "&drbkarow;",
            "®": "&reg;",
            "Ŕ": "&Racute;",
            "⟫": "&Rang;",
            "↠": "&twoheadrightarrow;",
            "⤖": "&Rarrtl;",
            "Ř": "&Rcaron;",
            "Ŗ": "&Rcedil;",
            "Р": "&Rcy;",
            "ℜ": "&realpart;",
            "∋": "&niv;",
            "⇋": "&lrhar;",
            "⥯": "&duhar;",
            "Ρ": "&Rho;",
            "⟩": "&rangle;",
            "→": "&srarr;",
            "⇥": "&rarrb;",
            "⇄": "&rlarr;",
            "⌉": "&rceil;",
            "⟧": "&robrk;",
            "⥝": "&RightDownTeeVector;",
            "⇂": "&downharpoonright;",
            "⥕": "&RightDownVectorBar;",
            "⌋": "&rfloor;",
            "⊢": "&vdash;",
            "↦": "&mapsto;",
            "⥛": "&RightTeeVector;",
            "⊳": "&vrtri;",
            "⧐": "&RightTriangleBar;",
            "⊵": "&trianglerighteq;",
            "⥏": "&RightUpDownVector;",
            "⥜": "&RightUpTeeVector;",
            "↾": "&upharpoonright;",
            "⥔": "&RightUpVectorBar;",
            "⇀": "&rightharpoonup;",
            "⥓": "&RightVectorBar;",
            "ℝ": "&reals;",
            "⥰": "&RoundImplies;",
            "⇛": "&rAarr;",
            "ℛ": "&realine;",
            "↱": "&rsh;",
            "⧴": "&RuleDelayed;",
            "Щ": "&SHCHcy;",
            "Ш": "&SHcy;",
            "Ь": "&SOFTcy;",
            "Ś": "&Sacute;",
            "⪼": "&Sc;",
            "Š": "&Scaron;",
            "Ş": "&Scedil;",
            "Ŝ": "&Scirc;",
            "С": "&Scy;",
            "𝔖": "&Sfr;",
            "↑": "&uparrow;",
            "Σ": "&Sigma;",
            "∘": "&compfn;",
            "𝕊": "&Sopf;",
            "√": "&radic;",
            "□": "&square;",
            "⊓": "&sqcap;",
            "⊏": "&sqsubset;",
            "⊑": "&sqsubseteq;",
            "⊐": "&sqsupset;",
            "⊒": "&sqsupseteq;",
            "⊔": "&sqcup;",
            "𝒮": "&Sscr;",
            "⋆": "&sstarf;",
            "⋐": "&Subset;",
            "⊆": "&subseteq;",
            "≻": "&succ;",
            "⪰": "&succeq;",
            "≽": "&succcurlyeq;",
            "≿": "&succsim;",
            "∑": "&sum;",
            "⋑": "&Supset;",
            "⊃": "&supset;",
            "⊇": "&supseteq;",
            "Þ": "&THORN;",
            "™": "&trade;",
            "Ћ": "&TSHcy;",
            "Ц": "&TScy;",
            "\t": "&Tab;",
            "Τ": "&Tau;",
            "Ť": "&Tcaron;",
            "Ţ": "&Tcedil;",
            "Т": "&Tcy;",
            "𝔗": "&Tfr;",
            "∴": "&therefore;",
            "Θ": "&Theta;",
            "  ": "&ThickSpace;",
            " ": "&thinsp;",
            "∼": "&thksim;",
            "≃": "&simeq;",
            "≅": "&cong;",
            "≈": "&thkap;",
            "𝕋": "&Topf;",
            "⃛": "&tdot;",
            "𝒯": "&Tscr;",
            "Ŧ": "&Tstrok;",
            "Ú": "&Uacute;",
            "↟": "&Uarr;",
            "⥉": "&Uarrocir;",
            "Ў": "&Ubrcy;",
            "Ŭ": "&Ubreve;",
            "Û": "&Ucirc;",
            "У": "&Ucy;",
            "Ű": "&Udblac;",
            "𝔘": "&Ufr;",
            "Ù": "&Ugrave;",
            "Ū": "&Umacr;",
            "_": "&lowbar;",
            "⏟": "&UnderBrace;",
            "⎵": "&bbrk;",
            "⏝": "&UnderParenthesis;",
            "⋃": "&xcup;",
            "⊎": "&uplus;",
            "Ų": "&Uogon;",
            "𝕌": "&Uopf;",
            "⤒": "&UpArrowBar;",
            "⇅": "&udarr;",
            "↕": "&varr;",
            "⥮": "&udhar;",
            "⊥": "&perp;",
            "↥": "&mapstoup;",
            "↖": "&nwarrow;",
            "↗": "&nearrow;",
            "ϒ": "&upsih;",
            "Υ": "&Upsilon;",
            "Ů": "&Uring;",
            "𝒰": "&Uscr;",
            "Ũ": "&Utilde;",
            "Ü": "&Uuml;",
            "⊫": "&VDash;",
            "⫫": "&Vbar;",
            "В": "&Vcy;",
            "⊩": "&Vdash;",
            "⫦": "&Vdashl;",
            "⋁": "&xvee;",
            "‖": "&Vert;",
            "∣": "&smid;",
            "|": "&vert;",
            "❘": "&VerticalSeparator;",
            "≀": "&wreath;",
            " ": "&hairsp;",
            "𝔙": "&Vfr;",
            "𝕍": "&Vopf;",
            "𝒱": "&Vscr;",
            "⊪": "&Vvdash;",
            "Ŵ": "&Wcirc;",
            "⋀": "&xwedge;",
            "𝔚": "&Wfr;",
            "𝕎": "&Wopf;",
            "𝒲": "&Wscr;",
            "𝔛": "&Xfr;",
            "Ξ": "&Xi;",
            "𝕏": "&Xopf;",
            "𝒳": "&Xscr;",
            "Я": "&YAcy;",
            "Ї": "&YIcy;",
            "Ю": "&YUcy;",
            "Ý": "&Yacute;",
            "Ŷ": "&Ycirc;",
            "Ы": "&Ycy;",
            "𝔜": "&Yfr;",
            "𝕐": "&Yopf;",
            "𝒴": "&Yscr;",
            "Ÿ": "&Yuml;",
            "Ж": "&ZHcy;",
            "Ź": "&Zacute;",
            "Ž": "&Zcaron;",
            "З": "&Zcy;",
            "Ż": "&Zdot;",
            "Ζ": "&Zeta;",
            "ℨ": "&zeetrf;",
            "ℤ": "&integers;",
            "𝒵": "&Zscr;",
            "á": "&aacute;",
            "ă": "&abreve;",
            "∾": "&mstpos;",
            "∾̳": "&acE;",
            "∿": "&acd;",
            "â": "&acirc;",
            "а": "&acy;",
            "æ": "&aelig;",
            "𝔞": "&afr;",
            "à": "&agrave;",
            "ℵ": "&aleph;",
            "α": "&alpha;",
            "ā": "&amacr;",
            "⨿": "&amalg;",
            "∧": "&wedge;",
            "⩕": "&andand;",
            "⩜": "&andd;",
            "⩘": "&andslope;",
            "⩚": "&andv;",
            "∠": "&angle;",
            "⦤": "&ange;",
            "∡": "&measuredangle;",
            "⦨": "&angmsdaa;",
            "⦩": "&angmsdab;",
            "⦪": "&angmsdac;",
            "⦫": "&angmsdad;",
            "⦬": "&angmsdae;",
            "⦭": "&angmsdaf;",
            "⦮": "&angmsdag;",
            "⦯": "&angmsdah;",
            "∟": "&angrt;",
            "⊾": "&angrtvb;",
            "⦝": "&angrtvbd;",
            "∢": "&angsph;",
            "⍼": "&angzarr;",
            "ą": "&aogon;",
            "𝕒": "&aopf;",
            "⩰": "&apE;",
            "⩯": "&apacir;",
            "≊": "&approxeq;",
            "≋": "&apid;",
            "'": "&apos;",
            "å": "&aring;",
            "𝒶": "&ascr;",
            "*": "&midast;",
            "ã": "&atilde;",
            "ä": "&auml;",
            "⨑": "&awint;",
            "⫭": "&bNot;",
            "≌": "&bcong;",
            "϶": "&bepsi;",
            "‵": "&bprime;",
            "∽": "&bsim;",
            "⋍": "&bsime;",
            "⊽": "&barvee;",
            "⌅": "&barwedge;",
            "⎶": "&bbrktbrk;",
            "б": "&bcy;",
            "„": "&ldquor;",
            "⦰": "&bemptyv;",
            "β": "&beta;",
            "ℶ": "&beth;",
            "≬": "&twixt;",
            "𝔟": "&bfr;",
            "◯": "&xcirc;",
            "⨀": "&xodot;",
            "⨁": "&xoplus;",
            "⨂": "&xotime;",
            "⨆": "&xsqcup;",
            "★": "&starf;",
            "▽": "&xdtri;",
            "△": "&xutri;",
            "⨄": "&xuplus;",
            "⤍": "&rbarr;",
            "⧫": "&lozf;",
            "▴": "&utrif;",
            "▾": "&dtrif;",
            "◂": "&ltrif;",
            "▸": "&rtrif;",
            "␣": "&blank;",
            "▒": "&blk12;",
            "░": "&blk14;",
            "▓": "&blk34;",
            "█": "&block;",
            "=⃥": "&bne;",
            "≡⃥": "&bnequiv;",
            "⌐": "&bnot;",
            "𝕓": "&bopf;",
            "⋈": "&bowtie;",
            "╗": "&boxDL;",
            "╔": "&boxDR;",
            "╖": "&boxDl;",
            "╓": "&boxDr;",
            "═": "&boxH;",
            "╦": "&boxHD;",
            "╩": "&boxHU;",
            "╤": "&boxHd;",
            "╧": "&boxHu;",
            "╝": "&boxUL;",
            "╚": "&boxUR;",
            "╜": "&boxUl;",
            "╙": "&boxUr;",
            "║": "&boxV;",
            "╬": "&boxVH;",
            "╣": "&boxVL;",
            "╠": "&boxVR;",
            "╫": "&boxVh;",
            "╢": "&boxVl;",
            "╟": "&boxVr;",
            "⧉": "&boxbox;",
            "╕": "&boxdL;",
            "╒": "&boxdR;",
            "┐": "&boxdl;",
            "┌": "&boxdr;",
            "╥": "&boxhD;",
            "╨": "&boxhU;",
            "┬": "&boxhd;",
            "┴": "&boxhu;",
            "⊟": "&minusb;",
            "⊞": "&plusb;",
            "⊠": "&timesb;",
            "╛": "&boxuL;",
            "╘": "&boxuR;",
            "┘": "&boxul;",
            "└": "&boxur;",
            "│": "&boxv;",
            "╪": "&boxvH;",
            "╡": "&boxvL;",
            "╞": "&boxvR;",
            "┼": "&boxvh;",
            "┤": "&boxvl;",
            "├": "&boxvr;",
            "¦": "&brvbar;",
            "𝒷": "&bscr;",
            "⁏": "&bsemi;",
            "\\": "&bsol;",
            "⧅": "&bsolb;",
            "⟈": "&bsolhsub;",
            "•": "&bullet;",
            "⪮": "&bumpE;",
            "ć": "&cacute;",
            "∩": "&cap;",
            "⩄": "&capand;",
            "⩉": "&capbrcup;",
            "⩋": "&capcap;",
            "⩇": "&capcup;",
            "⩀": "&capdot;",
            "∩︀": "&caps;",
            "⁁": "&caret;",
            "⩍": "&ccaps;",
            "č": "&ccaron;",
            "ç": "&ccedil;",
            "ĉ": "&ccirc;",
            "⩌": "&ccups;",
            "⩐": "&ccupssm;",
            "ċ": "&cdot;",
            "⦲": "&cemptyv;",
            "¢": "&cent;",
            "𝔠": "&cfr;",
            "ч": "&chcy;",
            "✓": "&checkmark;",
            "χ": "&chi;",
            "○": "&cir;",
            "⧃": "&cirE;",
            "ˆ": "&circ;",
            "≗": "&cire;",
            "↺": "&olarr;",
            "↻": "&orarr;",
            "Ⓢ": "&oS;",
            "⊛": "&oast;",
            "⊚": "&ocir;",
            "⊝": "&odash;",
            "⨐": "&cirfnint;",
            "⫯": "&cirmid;",
            "⧂": "&cirscir;",
            "♣": "&clubsuit;",
            ":": "&colon;",
            ",": "&comma;",
            "@": "&commat;",
            "∁": "&complement;",
            "⩭": "&congdot;",
            "𝕔": "&copf;",
            "℗": "&copysr;",
            "↵": "&crarr;",
            "✗": "&cross;",
            "𝒸": "&cscr;",
            "⫏": "&csub;",
            "⫑": "&csube;",
            "⫐": "&csup;",
            "⫒": "&csupe;",
            "⋯": "&ctdot;",
            "⤸": "&cudarrl;",
            "⤵": "&cudarrr;",
            "⋞": "&curlyeqprec;",
            "⋟": "&curlyeqsucc;",
            "↶": "&curvearrowleft;",
            "⤽": "&cularrp;",
            "∪": "&cup;",
            "⩈": "&cupbrcap;",
            "⩆": "&cupcap;",
            "⩊": "&cupcup;",
            "⊍": "&cupdot;",
            "⩅": "&cupor;",
            "∪︀": "&cups;",
            "↷": "&curvearrowright;",
            "⤼": "&curarrm;",
            "⋎": "&cuvee;",
            "⋏": "&cuwed;",
            "¤": "&curren;",
            "∱": "&cwint;",
            "⌭": "&cylcty;",
            "⥥": "&dHar;",
            "†": "&dagger;",
            "ℸ": "&daleth;",
            "‐": "&hyphen;",
            "⤏": "&rBarr;",
            "ď": "&dcaron;",
            "д": "&dcy;",
            "⇊": "&downdownarrows;",
            "⩷": "&eDDot;",
            "°": "&deg;",
            "δ": "&delta;",
            "⦱": "&demptyv;",
            "⥿": "&dfisht;",
            "𝔡": "&dfr;",
            "♦": "&diams;",
            "ϝ": "&gammad;",
            "⋲": "&disin;",
            "÷": "&divide;",
            "⋇": "&divonx;",
            "ђ": "&djcy;",
            "⌞": "&llcorner;",
            "⌍": "&dlcrop;",
            "$": "&dollar;",
            "𝕕": "&dopf;",
            "≑": "&eDot;",
            "∸": "&minusd;",
            "∔": "&plusdo;",
            "⊡": "&sdotb;",
            "⌟": "&lrcorner;",
            "⌌": "&drcrop;",
            "𝒹": "&dscr;",
            "ѕ": "&dscy;",
            "⧶": "&dsol;",
            "đ": "&dstrok;",
            "⋱": "&dtdot;",
            "▿": "&triangledown;",
            "⦦": "&dwangle;",
            "џ": "&dzcy;",
            "⟿": "&dzigrarr;",
            "é": "&eacute;",
            "⩮": "&easter;",
            "ě": "&ecaron;",
            "≖": "&eqcirc;",
            "ê": "&ecirc;",
            "≕": "&eqcolon;",
            "э": "&ecy;",
            "ė": "&edot;",
            "≒": "&fallingdotseq;",
            "𝔢": "&efr;",
            "⪚": "&eg;",
            "è": "&egrave;",
            "⪖": "&eqslantgtr;",
            "⪘": "&egsdot;",
            "⪙": "&el;",
            "⏧": "&elinters;",
            "ℓ": "&ell;",
            "⪕": "&eqslantless;",
            "⪗": "&elsdot;",
            "ē": "&emacr;",
            "∅": "&varnothing;",
            " ": "&emsp13;",
            " ": "&emsp14;",
            " ": "&emsp;",
            "ŋ": "&eng;",
            " ": "&ensp;",
            "ę": "&eogon;",
            "𝕖": "&eopf;",
            "⋕": "&epar;",
            "⧣": "&eparsl;",
            "⩱": "&eplus;",
            "ε": "&epsilon;",
            "ϵ": "&varepsilon;",
            "=": "&equals;",
            "≟": "&questeq;",
            "⩸": "&equivDD;",
            "⧥": "&eqvparsl;",
            "≓": "&risingdotseq;",
            "⥱": "&erarr;",
            "ℯ": "&escr;",
            "η": "&eta;",
            "ð": "&eth;",
            "ë": "&euml;",
            "€": "&euro;",
            "!": "&excl;",
            "ф": "&fcy;",
            "♀": "&female;",
            "ﬃ": "&ffilig;",
            "ﬀ": "&fflig;",
            "ﬄ": "&ffllig;",
            "𝔣": "&ffr;",
            "ﬁ": "&filig;",
            "fj": "&fjlig;",
            "♭": "&flat;",
            "ﬂ": "&fllig;",
            "▱": "&fltns;",
            "ƒ": "&fnof;",
            "𝕗": "&fopf;",
            "⋔": "&pitchfork;",
            "⫙": "&forkv;",
            "⨍": "&fpartint;",
            "½": "&half;",
            "⅓": "&frac13;",
            "¼": "&frac14;",
            "⅕": "&frac15;",
            "⅙": "&frac16;",
            "⅛": "&frac18;",
            "⅔": "&frac23;",
            "⅖": "&frac25;",
            "¾": "&frac34;",
            "⅗": "&frac35;",
            "⅜": "&frac38;",
            "⅘": "&frac45;",
            "⅚": "&frac56;",
            "⅝": "&frac58;",
            "⅞": "&frac78;",
            "⁄": "&frasl;",
            "⌢": "&sfrown;",
            "𝒻": "&fscr;",
            "⪌": "&gtreqqless;",
            "ǵ": "&gacute;",
            "γ": "&gamma;",
            "⪆": "&gtrapprox;",
            "ğ": "&gbreve;",
            "ĝ": "&gcirc;",
            "г": "&gcy;",
            "ġ": "&gdot;",
            "⪩": "&gescc;",
            "⪀": "&gesdot;",
            "⪂": "&gesdoto;",
            "⪄": "&gesdotol;",
            "⋛︀": "&gesl;",
            "⪔": "&gesles;",
            "𝔤": "&gfr;",
            "ℷ": "&gimel;",
            "ѓ": "&gjcy;",
            "⪒": "&glE;",
            "⪥": "&gla;",
            "⪤": "&glj;",
            "≩": "&gneqq;",
            "⪊": "&gnapprox;",
            "⪈": "&gneq;",
            "⋧": "&gnsim;",
            "𝕘": "&gopf;",
            "ℊ": "&gscr;",
            "⪎": "&gsime;",
            "⪐": "&gsiml;",
            "⪧": "&gtcc;",
            "⩺": "&gtcir;",
            "⋗": "&gtrdot;",
            "⦕": "&gtlPar;",
            "⩼": "&gtquest;",
            "⥸": "&gtrarr;",
            "≩︀": "&gvnE;",
            "ъ": "&hardcy;",
            "⥈": "&harrcir;",
            "↭": "&leftrightsquigarrow;",
            "ℏ": "&plankv;",
            "ĥ": "&hcirc;",
            "♥": "&heartsuit;",
            "…": "&mldr;",
            "⊹": "&hercon;",
            "𝔥": "&hfr;",
            "⤥": "&searhk;",
            "⤦": "&swarhk;",
            "⇿": "&hoarr;",
            "∻": "&homtht;",
            "↩": "&larrhk;",
            "↪": "&rarrhk;",
            "𝕙": "&hopf;",
            "―": "&horbar;",
            "𝒽": "&hscr;",
            "ħ": "&hstrok;",
            "⁃": "&hybull;",
            "í": "&iacute;",
            "î": "&icirc;",
            "и": "&icy;",
            "е": "&iecy;",
            "¡": "&iexcl;",
            "𝔦": "&ifr;",
            "ì": "&igrave;",
            "⨌": "&qint;",
            "∭": "&tint;",
            "⧜": "&iinfin;",
            "℩": "&iiota;",
            "ĳ": "&ijlig;",
            "ī": "&imacr;",
            "ı": "&inodot;",
            "⊷": "&imof;",
            "Ƶ": "&imped;",
            "℅": "&incare;",
            "∞": "&infin;",
            "⧝": "&infintie;",
            "⊺": "&intercal;",
            "⨗": "&intlarhk;",
            "⨼": "&iprod;",
            "ё": "&iocy;",
            "į": "&iogon;",
            "𝕚": "&iopf;",
            "ι": "&iota;",
            "¿": "&iquest;",
            "𝒾": "&iscr;",
            "⋹": "&isinE;",
            "⋵": "&isindot;",
            "⋴": "&isins;",
            "⋳": "&isinsv;",
            "ĩ": "&itilde;",
            "і": "&iukcy;",
            "ï": "&iuml;",
            "ĵ": "&jcirc;",
            "й": "&jcy;",
            "𝔧": "&jfr;",
            "ȷ": "&jmath;",
            "𝕛": "&jopf;",
            "𝒿": "&jscr;",
            "ј": "&jsercy;",
            "є": "&jukcy;",
            "κ": "&kappa;",
            "ϰ": "&varkappa;",
            "ķ": "&kcedil;",
            "к": "&kcy;",
            "𝔨": "&kfr;",
            "ĸ": "&kgreen;",
            "х": "&khcy;",
            "ќ": "&kjcy;",
            "𝕜": "&kopf;",
            "𝓀": "&kscr;",
            "⤛": "&lAtail;",
            "⤎": "&lBarr;",
            "⪋": "&lesseqqgtr;",
            "⥢": "&lHar;",
            "ĺ": "&lacute;",
            "⦴": "&laemptyv;",
            "λ": "&lambda;",
            "⦑": "&langd;",
            "⪅": "&lessapprox;",
            "«": "&laquo;",
            "⤟": "&larrbfs;",
            "⤝": "&larrfs;",
            "↫": "&looparrowleft;",
            "⤹": "&larrpl;",
            "⥳": "&larrsim;",
            "↢": "&leftarrowtail;",
            "⪫": "&lat;",
            "⤙": "&latail;",
            "⪭": "&late;",
            "⪭︀": "&lates;",
            "⤌": "&lbarr;",
            "❲": "&lbbrk;",
            "{": "&lcub;",
            "[": "&lsqb;",
            "⦋": "&lbrke;",
            "⦏": "&lbrksld;",
            "⦍": "&lbrkslu;",
            "ľ": "&lcaron;",
            "ļ": "&lcedil;",
            "л": "&lcy;",
            "⤶": "&ldca;",
            "⥧": "&ldrdhar;",
            "⥋": "&ldrushar;",
            "↲": "&ldsh;",
            "≤": "&leq;",
            "⇇": "&llarr;",
            "⋋": "&lthree;",
            "⪨": "&lescc;",
            "⩿": "&lesdot;",
            "⪁": "&lesdoto;",
            "⪃": "&lesdotor;",
            "⋚︀": "&lesg;",
            "⪓": "&lesges;",
            "⋖": "&ltdot;",
            "⥼": "&lfisht;",
            "𝔩": "&lfr;",
            "⪑": "&lgE;",
            "⥪": "&lharul;",
            "▄": "&lhblk;",
            "љ": "&ljcy;",
            "⥫": "&llhard;",
            "◺": "&lltri;",
            "ŀ": "&lmidot;",
            "⎰": "&lmoustache;",
            "≨": "&lneqq;",
            "⪉": "&lnapprox;",
            "⪇": "&lneq;",
            "⋦": "&lnsim;",
            "⟬": "&loang;",
            "⇽": "&loarr;",
            "⟼": "&xmap;",
            "↬": "&rarrlp;",
            "⦅": "&lopar;",
            "𝕝": "&lopf;",
            "⨭": "&loplus;",
            "⨴": "&lotimes;",
            "∗": "&lowast;",
            "◊": "&lozenge;",
            "(": "&lpar;",
            "⦓": "&lparlt;",
            "⥭": "&lrhard;",
            "‎": "&lrm;",
            "⊿": "&lrtri;",
            "‹": "&lsaquo;",
            "𝓁": "&lscr;",
            "⪍": "&lsime;",
            "⪏": "&lsimg;",
            "‚": "&sbquo;",
            "ł": "&lstrok;",
            "⪦": "&ltcc;",
            "⩹": "&ltcir;",
            "⋉": "&ltimes;",
            "⥶": "&ltlarr;",
            "⩻": "&ltquest;",
            "⦖": "&ltrPar;",
            "◃": "&triangleleft;",
            "⥊": "&lurdshar;",
            "⥦": "&luruhar;",
            "≨︀": "&lvnE;",
            "∺": "&mDDot;",
            "¯": "&strns;",
            "♂": "&male;",
            "✠": "&maltese;",
            "▮": "&marker;",
            "⨩": "&mcomma;",
            "м": "&mcy;",
            "—": "&mdash;",
            "𝔪": "&mfr;",
            "℧": "&mho;",
            "µ": "&micro;",
            "⫰": "&midcir;",
            "−": "&minus;",
            "⨪": "&minusdu;",
            "⫛": "&mlcp;",
            "⊧": "&models;",
            "𝕞": "&mopf;",
            "𝓂": "&mscr;",
            "μ": "&mu;",
            "⊸": "&mumap;",
            "⋙̸": "&nGg;",
            "≫⃒": "&nGt;",
            "⇍": "&nlArr;",
            "⇎": "&nhArr;",
            "⋘̸": "&nLl;",
            "≪⃒": "&nLt;",
            "⇏": "&nrArr;",
            "⊯": "&nVDash;",
            "⊮": "&nVdash;",
            "ń": "&nacute;",
            "∠⃒": "&nang;",
            "⩰̸": "&napE;",
            "≋̸": "&napid;",
            "ŉ": "&napos;",
            "♮": "&natural;",
            "⩃": "&ncap;",
            "ň": "&ncaron;",
            "ņ": "&ncedil;",
            "⩭̸": "&ncongdot;",
            "⩂": "&ncup;",
            "н": "&ncy;",
            "–": "&ndash;",
            "⇗": "&neArr;",
            "⤤": "&nearhk;",
            "≐̸": "&nedot;",
            "⤨": "&toea;",
            "𝔫": "&nfr;",
            "↮": "&nleftrightarrow;",
            "⫲": "&nhpar;",
            "⋼": "&nis;",
            "⋺": "&nisd;",
            "њ": "&njcy;",
            "≦̸": "&nleqq;",
            "↚": "&nleftarrow;",
            "‥": "&nldr;",
            "𝕟": "&nopf;",
            "¬": "&not;",
            "⋹̸": "&notinE;",
            "⋵̸": "&notindot;",
            "⋷": "&notinvb;",
            "⋶": "&notinvc;",
            "⋾": "&notnivb;",
            "⋽": "&notnivc;",
            "⫽⃥": "&nparsl;",
            "∂̸": "&npart;",
            "⨔": "&npolint;",
            "↛": "&nrightarrow;",
            "⤳̸": "&nrarrc;",
            "↝̸": "&nrarrw;",
            "𝓃": "&nscr;",
            "⊄": "&nsub;",
            "⫅̸": "&nsubseteqq;",
            "⊅": "&nsup;",
            "⫆̸": "&nsupseteqq;",
            "ñ": "&ntilde;",
            "ν": "&nu;",
            "#": "&num;",
            "№": "&numero;",
            " ": "&numsp;",
            "⊭": "&nvDash;",
            "⤄": "&nvHarr;",
            "≍⃒": "&nvap;",
            "⊬": "&nvdash;",
            "≥⃒": "&nvge;",
            ">⃒": "&nvgt;",
            "⧞": "&nvinfin;",
            "⤂": "&nvlArr;",
            "≤⃒": "&nvle;",
            "<⃒": "&nvlt;",
            "⊴⃒": "&nvltrie;",
            "⤃": "&nvrArr;",
            "⊵⃒": "&nvrtrie;",
            "∼⃒": "&nvsim;",
            "⇖": "&nwArr;",
            "⤣": "&nwarhk;",
            "⤧": "&nwnear;",
            "ó": "&oacute;",
            "ô": "&ocirc;",
            "о": "&ocy;",
            "ő": "&odblac;",
            "⨸": "&odiv;",
            "⦼": "&odsold;",
            "œ": "&oelig;",
            "⦿": "&ofcir;",
            "𝔬": "&ofr;",
            "˛": "&ogon;",
            "ò": "&ograve;",
            "⧁": "&ogt;",
            "⦵": "&ohbar;",
            "⦾": "&olcir;",
            "⦻": "&olcross;",
            "⧀": "&olt;",
            "ō": "&omacr;",
            "ω": "&omega;",
            "ο": "&omicron;",
            "⦶": "&omid;",
            "𝕠": "&oopf;",
            "⦷": "&opar;",
            "⦹": "&operp;",
            "∨": "&vee;",
            "⩝": "&ord;",
            "ℴ": "&oscr;",
            "ª": "&ordf;",
            "º": "&ordm;",
            "⊶": "&origof;",
            "⩖": "&oror;",
            "⩗": "&orslope;",
            "⩛": "&orv;",
            "ø": "&oslash;",
            "⊘": "&osol;",
            "õ": "&otilde;",
            "⨶": "&otimesas;",
            "ö": "&ouml;",
            "⌽": "&ovbar;",
            "¶": "&para;",
            "⫳": "&parsim;",
            "⫽": "&parsl;",
            "п": "&pcy;",
            "%": "&percnt;",
            ".": "&period;",
            "‰": "&permil;",
            "‱": "&pertenk;",
            "𝔭": "&pfr;",
            "φ": "&phi;",
            "ϕ": "&varphi;",
            "☎": "&phone;",
            "π": "&pi;",
            "ϖ": "&varpi;",
            "ℎ": "&planckh;",
            "+": "&plus;",
            "⨣": "&plusacir;",
            "⨢": "&pluscir;",
            "⨥": "&plusdu;",
            "⩲": "&pluse;",
            "⨦": "&plussim;",
            "⨧": "&plustwo;",
            "⨕": "&pointint;",
            "𝕡": "&popf;",
            "£": "&pound;",
            "⪳": "&prE;",
            "⪷": "&precapprox;",
            "⪹": "&prnap;",
            "⪵": "&prnE;",
            "⋨": "&prnsim;",
            "′": "&prime;",
            "⌮": "&profalar;",
            "⌒": "&profline;",
            "⌓": "&profsurf;",
            "⊰": "&prurel;",
            "𝓅": "&pscr;",
            "ψ": "&psi;",
            " ": "&puncsp;",
            "𝔮": "&qfr;",
            "𝕢": "&qopf;",
            "⁗": "&qprime;",
            "𝓆": "&qscr;",
            "⨖": "&quatint;",
            "?": "&quest;",
            "⤜": "&rAtail;",
            "⥤": "&rHar;",
            "∽̱": "&race;",
            "ŕ": "&racute;",
            "⦳": "&raemptyv;",
            "⦒": "&rangd;",
            "⦥": "&range;",
            "»": "&raquo;",
            "⥵": "&rarrap;",
            "⤠": "&rarrbfs;",
            "⤳": "&rarrc;",
            "⤞": "&rarrfs;",
            "⥅": "&rarrpl;",
            "⥴": "&rarrsim;",
            "↣": "&rightarrowtail;",
            "↝": "&rightsquigarrow;",
            "⤚": "&ratail;",
            "∶": "&ratio;",
            "❳": "&rbbrk;",
            "}": "&rcub;",
            "]": "&rsqb;",
            "⦌": "&rbrke;",
            "⦎": "&rbrksld;",
            "⦐": "&rbrkslu;",
            "ř": "&rcaron;",
            "ŗ": "&rcedil;",
            "р": "&rcy;",
            "⤷": "&rdca;",
            "⥩": "&rdldhar;",
            "↳": "&rdsh;",
            "▭": "&rect;",
            "⥽": "&rfisht;",
            "𝔯": "&rfr;",
            "⥬": "&rharul;",
            "ρ": "&rho;",
            "ϱ": "&varrho;",
            "⇉": "&rrarr;",
            "⋌": "&rthree;",
            "˚": "&ring;",
            "‏": "&rlm;",
            "⎱": "&rmoustache;",
            "⫮": "&rnmid;",
            "⟭": "&roang;",
            "⇾": "&roarr;",
            "⦆": "&ropar;",
            "𝕣": "&ropf;",
            "⨮": "&roplus;",
            "⨵": "&rotimes;",
            ")": "&rpar;",
            "⦔": "&rpargt;",
            "⨒": "&rppolint;",
            "›": "&rsaquo;",
            "𝓇": "&rscr;",
            "⋊": "&rtimes;",
            "▹": "&triangleright;",
            "⧎": "&rtriltri;",
            "⥨": "&ruluhar;",
            "℞": "&rx;",
            "ś": "&sacute;",
            "⪴": "&scE;",
            "⪸": "&succapprox;",
            "š": "&scaron;",
            "ş": "&scedil;",
            "ŝ": "&scirc;",
            "⪶": "&succneqq;",
            "⪺": "&succnapprox;",
            "⋩": "&succnsim;",
            "⨓": "&scpolint;",
            "с": "&scy;",
            "⋅": "&sdot;",
            "⩦": "&sdote;",
            "⇘": "&seArr;",
            "§": "&sect;",
            ";": "&semi;",
            "⤩": "&tosa;",
            "✶": "&sext;",
            "𝔰": "&sfr;",
            "♯": "&sharp;",
            "щ": "&shchcy;",
            "ш": "&shcy;",
            "­": "&shy;",
            "σ": "&sigma;",
            "ς": "&varsigma;",
            "⩪": "&simdot;",
            "⪞": "&simg;",
            "⪠": "&simgE;",
            "⪝": "&siml;",
            "⪟": "&simlE;",
            "≆": "&simne;",
            "⨤": "&simplus;",
            "⥲": "&simrarr;",
            "⨳": "&smashp;",
            "⧤": "&smeparsl;",
            "⌣": "&ssmile;",
            "⪪": "&smt;",
            "⪬": "&smte;",
            "⪬︀": "&smtes;",
            "ь": "&softcy;",
            "/": "&sol;",
            "⧄": "&solb;",
            "⌿": "&solbar;",
            "𝕤": "&sopf;",
            "♠": "&spadesuit;",
            "⊓︀": "&sqcaps;",
            "⊔︀": "&sqcups;",
            "𝓈": "&sscr;",
            "☆": "&star;",
            "⊂": "&subset;",
            "⫅": "&subseteqq;",
            "⪽": "&subdot;",
            "⫃": "&subedot;",
            "⫁": "&submult;",
            "⫋": "&subsetneqq;",
            "⊊": "&subsetneq;",
            "⪿": "&subplus;",
            "⥹": "&subrarr;",
            "⫇": "&subsim;",
            "⫕": "&subsub;",
            "⫓": "&subsup;",
            "♪": "&sung;",
            "¹": "&sup1;",
            "²": "&sup2;",
            "³": "&sup3;",
            "⫆": "&supseteqq;",
            "⪾": "&supdot;",
            "⫘": "&supdsub;",
            "⫄": "&supedot;",
            "⟉": "&suphsol;",
            "⫗": "&suphsub;",
            "⥻": "&suplarr;",
            "⫂": "&supmult;",
            "⫌": "&supsetneqq;",
            "⊋": "&supsetneq;",
            "⫀": "&supplus;",
            "⫈": "&supsim;",
            "⫔": "&supsub;",
            "⫖": "&supsup;",
            "⇙": "&swArr;",
            "⤪": "&swnwar;",
            "ß": "&szlig;",
            "⌖": "&target;",
            "τ": "&tau;",
            "ť": "&tcaron;",
            "ţ": "&tcedil;",
            "т": "&tcy;",
            "⌕": "&telrec;",
            "𝔱": "&tfr;",
            "θ": "&theta;",
            "ϑ": "&vartheta;",
            "þ": "&thorn;",
            "×": "&times;",
            "⨱": "&timesbar;",
            "⨰": "&timesd;",
            "⌶": "&topbot;",
            "⫱": "&topcir;",
            "𝕥": "&topf;",
            "⫚": "&topfork;",
            "‴": "&tprime;",
            "▵": "&utri;",
            "≜": "&trie;",
            "◬": "&tridot;",
            "⨺": "&triminus;",
            "⨹": "&triplus;",
            "⧍": "&trisb;",
            "⨻": "&tritime;",
            "⏢": "&trpezium;",
            "𝓉": "&tscr;",
            "ц": "&tscy;",
            "ћ": "&tshcy;",
            "ŧ": "&tstrok;",
            "⥣": "&uHar;",
            "ú": "&uacute;",
            "ў": "&ubrcy;",
            "ŭ": "&ubreve;",
            "û": "&ucirc;",
            "у": "&ucy;",
            "ű": "&udblac;",
            "⥾": "&ufisht;",
            "𝔲": "&ufr;",
            "ù": "&ugrave;",
            "▀": "&uhblk;",
            "⌜": "&ulcorner;",
            "⌏": "&ulcrop;",
            "◸": "&ultri;",
            "ū": "&umacr;",
            "ų": "&uogon;",
            "𝕦": "&uopf;",
            "υ": "&upsilon;",
            "⇈": "&uuarr;",
            "⌝": "&urcorner;",
            "⌎": "&urcrop;",
            "ů": "&uring;",
            "◹": "&urtri;",
            "𝓊": "&uscr;",
            "⋰": "&utdot;",
            "ũ": "&utilde;",
            "ü": "&uuml;",
            "⦧": "&uwangle;",
            "⫨": "&vBar;",
            "⫩": "&vBarv;",
            "⦜": "&vangrt;",
            "⊊︀": "&vsubne;",
            "⫋︀": "&vsubnE;",
            "⊋︀": "&vsupne;",
            "⫌︀": "&vsupnE;",
            "в": "&vcy;",
            "⊻": "&veebar;",
            "≚": "&veeeq;",
            "⋮": "&vellip;",
            "𝔳": "&vfr;",
            "𝕧": "&vopf;",
            "𝓋": "&vscr;",
            "⦚": "&vzigzag;",
            "ŵ": "&wcirc;",
            "⩟": "&wedbar;",
            "≙": "&wedgeq;",
            "℘": "&wp;",
            "𝔴": "&wfr;",
            "𝕨": "&wopf;",
            "𝓌": "&wscr;",
            "𝔵": "&xfr;",
            "ξ": "&xi;",
            "⋻": "&xnis;",
            "𝕩": "&xopf;",
            "𝓍": "&xscr;",
            "ý": "&yacute;",
            "я": "&yacy;",
            "ŷ": "&ycirc;",
            "ы": "&ycy;",
            "¥": "&yen;",
            "𝔶": "&yfr;",
            "ї": "&yicy;",
            "𝕪": "&yopf;",
            "𝓎": "&yscr;",
            "ю": "&yucy;",
            "ÿ": "&yuml;",
            "ź": "&zacute;",
            "ž": "&zcaron;",
            "з": "&zcy;",
            "ż": "&zdot;",
            "ζ": "&zeta;",
            "𝔷": "&zfr;",
            "ж": "&zhcy;",
            "⇝": "&zigrarr;",
            "𝕫": "&zopf;",
            "𝓏": "&zscr;",
            "‍": "&zwj;",
            "‌": "&zwnj;"
        }
    }
};
