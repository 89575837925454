import React, { useEffect, useState } from "react";
import {
  Platform,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Util from "src/utility";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import ContentRenderer from "src/components/ContentRenderer";

const DEFAULT_CONFIRMATION_MESSAGE =
  "Your response has been recorded. Thanks for submitting this form.";

export default function Form({ route, navigation, portalContentOverride }) {
  const {
    params: {
      navName,
      txtName,
      icon,
      portalType,
      allowedAccountTypes,
      onUpdatePortal
    } = {}
  } = route || {};
  const [loading, setLoading] = useState(true);
  const [formContent, setFormContent] = useState(null);
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const { googleFormID, content: contentItems = [] } = formContent || {};

  const userIsDemoAccount =
    Firebase.getUserID() === Glob.get("demoAccountUserID");

  // For the time being, formID is the same as the portalID. This fact is used in formResponses.js too
  const formID = navName;

  const requiredQuestionsAreUnanswered = contentItems.some(
    (item) =>
      item.type === "form_question" &&
      item.isRequired &&
      (!answers[item.id] ||
        (typeof answers[item.id] === "string" &&
          answers[item.id].trim() === ""))
  );

  const title = formContent?.title || txtName || "";

  useEffect(() => {
    Analytics.logEvent("view_form", { navName, title });
    Database.getPortalContentNew(navName).then((contentFetched) => {
      const content = portalContentOverride || contentFetched;
      if (contentItems) {
        Analytics.logEvent("action_form_loadedQuestions", {
          navName,
          title,
          contentItems
        });
        setFormContent(content);
        setLoading(false);
      }
    });
  }, []);

  const openSettings = () => {
    Analytics.logEvent("touch_form_settingsButton", {
      key: navName
    });
    navigation.goBack();
    navigation.push("editPortal", {
      portalMetadata: {
        navName,
        icon,
        portalType,
        txtName,
        allowedAccountTypes
      },
      onUpdatePortal
    });
  };

  const submitForm = () => {
    setSubmitted(true);
    Analytics.logEvent("touch_form_submit", { navName, title });

    if (userIsDemoAccount) return;

    const questionsToSaveAnswersFor = contentItems.filter(
      (item) =>
        item.type === "form_question" &&
        item.prefillWithSavedFormAnswers &&
        item.id &&
        answers[item.id]
    );
    const answersToSave = {};
    questionsToSaveAnswersFor.forEach((q) => {
      answersToSave[q.id] = answers[q.id];
    });
    if (Object.keys(answersToSave).length > 0) {
      Analytics.logEvent("action_form_savingAnswers", {
        navName,
        title,
        answersToSave
      });
      Database.setUserSavedFormAnswers(navName, answersToSave);
    }

    const answersWithQuestions = Object.keys(answers).reduce(
      (acc, questionId) => {
        const question = contentItems.find(
          (item) => item.type === "form_question" && item.id === questionId
        );
        acc[questionId] = {
          answer: answers[questionId],
          question: {
            inputType: question.inputType,
            question: question.question,
            ...(question.listItemInputName
              ? {
                  listItemInputName: question.listItemInputName
                }
              : {})
          }
        };
        return acc;
      },
      {}
    );

    Database.postFormResponse(formID, answersWithQuestions);

    if (googleFormID) {
      const questionsWithAnswers = contentItems
        .filter((item) => item.type === "form_question" && answers[item.id])
        .map((q) => ({
          ...q,
          answer: answers[q.id]
        }));
      const googleFormFieldIDs = questionsWithAnswers.map(
        (q) => q.googleFormFieldID
      );
      const answerValues = questionsWithAnswers.map((q) => {
        if (
          Array.isArray(q.answer) &&
          q.answer.length > 0 &&
          typeof q.answer[0] === "object" &&
          q.answer[0].text
        ) {
          const answerString = q.answer
            .map((a) =>
              a.inputValue
                ? `${a.text} — ${q.listItemInputName}: ${a.inputValue}`
                : a.text
            )
            .join(", ");
          return answerString;
        }
        return q.answer;
      });
      if (googleFormFieldIDs.length === answerValues.length) {
        Analytics.logEvent("action_form_submittingGoogleForm", {
          navName,
          title,
          googleFormID,
          answers
        });
        Util.fillGoogleForm(googleFormID, googleFormFieldIDs, answerValues);
      }
    }
  };

  if (submitted) {
    return (
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "white" }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <NavBar
          navigation={navigation}
          text={title}
          RightButton={
            <TouchableOpacity
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "flex-end",
                paddingRight: 20
              }}
              activeOpacity={0.6}
              onPress={openSettings}
            >
              <MaterialIcons
                name="settings"
                size={Glob.deviceHasTabletDimensions() ? 42 : 26}
                color="white"
              />
            </TouchableOpacity>
          }
        />
        <ScrollView
          style={{ flex: 1, padding: 20 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <Text style={{ fontSize: 18 }}>
            {userIsDemoAccount ? "DEMO:\n\n" : ""}
            {formContent?.confirmationMessage || DEFAULT_CONFIRMATION_MESSAGE}
          </Text>
          {!!formContent?.promptToSubmitAnotherResponse && (
            <Button
              text="Submit Another Response"
              onPress={() => {
                Analytics.logEvent("touch_form_submitAnotherResponse", {
                  navName,
                  title
                });
                setAnswers({});
                setSubmitted(false);
              }}
              style={{ marginTop: 40 }}
              textStyle={{ fontWeight: "bold" }}
            />
          )}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  if (loading)
    return (
      <View style={{ flex: 1, backgroundColor: "white", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );

  return (
    <ContentRenderer
      route={route}
      navigation={navigation}
      formAnswers={answers}
      setFormAnswers={setAnswers}
      formContent={formContent}
      FormSubmitButton={
        <Button
          text="Submit"
          style={{
            marginTop: 40,
            marginBottom: 300,
            opacity: requiredQuestionsAreUnanswered ? 0.3 : 1
          }}
          textStyle={{ fontWeight: "bold" }}
          disabled={requiredQuestionsAreUnanswered}
          onPress={submitForm}
        />
      }
    />
  );
}
