import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import AlertModal from "src/components/AlertModal";
import SearchBar from "src/components/searchBar";
import Moment from "moment";

const { width } = Glob.get("dimensions");

export default function FormResponses({ navigation }) {
  const [loadedForms, setLoadedForms] = useState(false);
  const [forms, setForms] = useState(null);
  const [alert, setAlert] = useState(null);
  const [filteredForms, setFilteredForms] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_formResponses");
    Database.subscribeToAllForms(async (newForms) => {
      const formIDs = Object.keys(newForms || {});

      const formattedForms = {};
      await Promise.all(
        formIDs.map(async (formID) => {
          const portalContent = await Database.getPortalContentNew(formID);
          formattedForms[formID] = {
            ...portalContent,
            ...newForms[formID]
          };
        })
      );

      setForms(formattedForms);
      setFilteredForms(formattedForms);
      setLoadedForms(true);
    });

    return () => {
      Database.unsubscribeFromAllForms();
    };
  }, []);

  const createSearchableText = (form, response) => {
    const answers = Object.values(response.answers || {});
    const answerTexts = answers
      .map((answer) => {
        if (answer.question.inputType === "listBuilder") {
          return answer.answer
            .map((item) =>
              item.inputValue
                ? `${item.text} ${answer.question.listItemInputName}: ${item.inputValue}`
                : item.text
            )
            .join(" ");
        }
        return `${answer.question.question}: ${answer.answer}`;
      })
      .join(" ");

    return `${form.title} ${new Date(
      response.timestamp
    ).toLocaleDateString()} ${answerTexts}`.toLowerCase();
  };

  const handleSearch = (query) => {
    if (!query.trim()) {
      setFilteredForms(forms);
      return;
    }

    const searchResults = {};
    Object.entries(forms || {}).forEach(([formId, form]) => {
      const matchingResponses = {};
      Object.entries(form.responses || {}).forEach(([responseId, response]) => {
        const searchableText = createSearchableText(form, response);
        if (searchableText.includes(query.toLowerCase())) {
          matchingResponses[responseId] = response;
        }
      });

      if (Object.keys(matchingResponses).length > 0) {
        searchResults[formId] = {
          ...form,
          responses: matchingResponses
        };
      }
    });

    setFilteredForms(searchResults);
  };

  if (!loadedForms)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Form Responses" />
        <ScrollView style={{ paddingHorizontal: 20, width }}>
          <ActivityIndicator size="large" style={{ marginTop: 80 }} />
        </ScrollView>
      </View>
    );

  if (!forms || forms.length < 1)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Form Responses" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 24,
              textAlign: "center",
              marginTop: 80
            }}
          >
            No form responses yet.
          </Text>
        </ScrollView>
      </View>
    );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Form Responses" />
      <SearchBar
        placeholder="Search responses..."
        onChangeText={handleSearch}
        customWidth={width - 30}
      />
      <ScrollView
        style={{ width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        {Object.values(filteredForms || {}).map((form, formIndex) => (
          <View key={formIndex} style={styles.formContainer}>
            <Text style={styles.formTitle}>{form.title}</Text>
            {Object.entries(form.responses || {}).map(
              ([responseId, response]) => (
                <View key={responseId} style={styles.responseContainer}>
                  <Text style={styles.responseTimestamp}>
                    Submitted:{" "}
                    {Moment(response.timestamp).format("MMM D, YYYY h:mm A")}
                  </Text>
                  {Object.values(response.answers || {}).map(
                    (answer, answerIndex) => (
                      <View key={answerIndex} style={styles.answerContainer}>
                        <Text style={styles.questionText}>
                          {answer.question.question}
                        </Text>
                        {answer.question.inputType === "listBuilder" ? (
                          <View>
                            {answer.answer.map((item, itemIndex) => (
                              <Text key={itemIndex} style={styles.answerText}>
                                •{" "}
                                {item.inputValue
                                  ? `${item.text} — ${answer.question.listItemInputName}: ${item.inputValue}`
                                  : item.text}
                              </Text>
                            ))}
                          </View>
                        ) : (
                          <Text style={styles.answerText}>{answer.answer}</Text>
                        )}
                      </View>
                    )
                  )}
                </View>
              )
            )}
          </View>
        ))}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  formContainer: {
    padding: 16
  },
  formTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 12
  },
  responseContainer: {
    backgroundColor: "#f5f5f5",
    padding: 12,
    borderRadius: 8,
    marginBottom: 12
  },
  responseTimestamp: {
    fontSize: 14,
    color: "#666",
    marginBottom: 8
  },
  answerContainer: {
    marginBottom: 8
  },
  questionText: {
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 4
  },
  answerText: {
    fontSize: 15,
    color: "#333",
    marginLeft: 8
  }
});
